import * as React from 'react';

import { NumberField } from '../number-field';

interface ICoordinate {
  lat?: number;
  lng?: number;
}

interface ICoordinateProps {
  value: ICoordinate;
  onChange(value: ICoordinate): void;
  label?: string;
  required?: boolean;
  className?: string;
  invalidLat?: boolean;
  invalidLng?: boolean;
}

const validateCoordinate = (value?: number) => {
  if (!value) {
    return value;
  } else if (value < -180) {
    return -180;
  } else if (value > 180) {
    return 180;
  }

  return value;
};

export const CoordinateField = (props: ICoordinateProps) => {
  const { className, label, required, value, onChange, invalidLat, invalidLng } = props;

  return (
    <div className={className}>
      {label && (
        <label className="form-label">
          {label}&nbsp;
          {required && <span className="text-danger">*</span>}
        </label>
      )}
      <div className="row">
        <NumberField
          invalid={invalidLat}
          className="col-sm-6"
          placeholder="широта от -180 до 180"
          minValue={-180}
          maxValue={180}
          fractionalLimit={6}
          onChange={lat => onChange({ ...value, lat: validateCoordinate(lat) })}
          value={value.lat}
        />
        <NumberField
          invalid={invalidLng}
          className="col-sm-6"
          placeholder="долгота от -180 до 180"
          minValue={-180}
          maxValue={180}
          fractionalLimit={6}
          onChange={lng => onChange({ ...value, lng: validateCoordinate(lng) })}
          value={value.lng}
        />
      </div>
    </div>
  );
};
