import * as React from 'react';

import { mergeStyles } from '@cian/utils/lib/shared/style';

import { officeIsValid } from '../../../common/helpers';
import { IOffice } from '../../../common/interfaces';
import { CoordinateField } from '../../coordinate-field';
import { TextField } from '../../text-field';

import * as styles from './index.css';

interface IProps {
  useValidation: boolean;
  office: IOffice;
  indexNumber: number;
  active: boolean;
  onToggle(): void;
  onDelete(): void;
  onChange(office: IOffice): void;
}

export const OfficeTab = (props: IProps) => {
  const { office, active, indexNumber, onChange, onToggle, onDelete, useValidation } = props;
  const officeIsInvalid = useValidation && !officeIsValid(office);

  return (
    <div {...mergeStyles(['card', 'panel-default mb-2'], styles['container'], officeIsInvalid && styles['invalid'])}>
      <div {...mergeStyles('card-heading p-2', styles['head'])}>
        <h5 className="card-title m-0">
          <a
            className="collapsed text-decoration-none"
            onClick={e => {
              e.preventDefault();
              onToggle();
            }}
          >
            {`${office.name ? office.name : `Офис №${indexNumber}`} ${office.isMain ? '(главный) ' : ''}`}
            <i className={`bi-chevron-${active ? 'up' : 'down'}`} />
          </a>
        </h5>
        <button className="btn btn-xs btn-danger" onClick={onDelete}>
          Удалить
        </button>
      </div>
      <div {...mergeStyles('panel-collapse collapse', active ? 'show' : '')}>
        <div className="card-body">
          <div className="row">
            <TextField
              required
              invalid={useValidation && !office.name}
              containerClass="col-md-6"
              value={office.name}
              label="Название"
              maxLength={255}
              onChange={name => onChange({ ...office, name })}
            />
            <TextField
              required
              invalid={useValidation && !office.phone}
              containerClass="col-md-6"
              value={office.phone}
              label="Телефон"
              maxLength={255}
              onChange={phone => onChange({ ...office, phone })}
            />
            <TextField
              required
              invalid={useValidation && !office.address}
              containerClass="col-md-6"
              value={office.address}
              label="Адрес"
              maxLength={255}
              onChange={address => onChange({ ...office, address })}
            />
            <CoordinateField
              required
              invalidLat={useValidation && !office.mapLat}
              invalidLng={useValidation && !office.mapLng}
              className="col-md-6 form-group"
              value={{ lat: office.mapLat, lng: office.mapLng }}
              label="Координаты офиса"
              onChange={value => onChange({ ...office, mapLng: value.lng || 0, mapLat: value.lat || 0 })}
            />
            <div className="col-xs-12 form-group">
              <label className={styles['checkbox']}>
                <input
                  type="checkbox"
                  checked={office.isMain}
                  onChange={e => onChange({ ...office, isMain: e.currentTarget.checked })}
                />
                &nbsp; Главный офис
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
