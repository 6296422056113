import { IAdminGetSpecialPromoSchema, ISubstitutiveJKSchema } from '../../types/adminGetSpecialPromo';
import { IAdminCreateSpecialPromoRequest } from '../../repositories/special-promos/v3/admin-create-special-promo';
import { ICreateBannerSchema } from '../../repositories/special-promos/entities/schemas/CreateBannerSchema';
import { IAdminUpdateSpecialPromoRequest } from '../../repositories/special-promos/v3/admin-update-special-promo';
import { EStatus } from '../../repositories/special-promos/entities/schemas/AdminSimpleSpecialPromoSchema';
import { IHttpApi } from '@cian/http-api/shared';

import { bannerIsValid, colorHexIsValid, containsOnlyTrue, imageIsValid, officeIsValid } from '../../common/helpers';
import {
  EMPTY_BANNER,
  EMPTY_MAP_SETTINGS,
  IBanner,
  IBuilderInfo,
  ICompetitiveJk,
  IMapSettingsJk,
  IMapSettingsOffice,
  IOffice,
  ISpecialPromoLog,
  SpecialPromoStatusTypes,
  TSpecialPromoStatus,
} from '../../common/interfaces';
import { IBuilder } from '../../http/get-builder-list';
import { IJkForList } from '../../http/get-jks-by-builder';
import { DEFAULT_SELECTED_REGIONS, IRegion } from '../../http/get-regions';
import { IImage } from '../image-select';

const DEFAULT_COLOR_HEX = '#00aeef';
const DEFAULT_TEXT_COLOR_HEX = '#ffffff';

export interface IFormProps {
  httpApi?: IHttpApi;
  specialPromo?: IAdminGetSpecialPromoSchema;
  saving: boolean;
  title: string;
  builders: IBuilder[];
  regions: IRegion[];
  builderJks: IJkForList[];
  competitiveJks: IJkForList[];
  history?: ISpecialPromoLog[];
  onSubmit(value: IAdminUpdateSpecialPromoRequest | IAdminCreateSpecialPromoRequest): void;
}

export interface IFormState {
  id?: number;
  builderId: number;
  disclaimer: string;
  logoApp: IImage;
  logoMobile: IImage;
  logoSmall: IImage;
  textShowOffers: string;
  pinImage: IImage;
  nameGenitive: string;
  name: string;
  regionList: number[];
  colorHex: string;
  textColorHex: string;
  status: TSpecialPromoStatus;
  bannerList: IBanner[];
  logoBig: IImage;
  builderJks: IJkForList[];
  competitiveJks: IJkForList[];
  jkList: number[];
  competitiveJkList: ICompetitiveJk[];
  mapSettingsOffice?: IMapSettingsOffice;
  mapSettingsJk: IMapSettingsJk;
  officeList?: IOffice[];
  info: IBuilderInfo;
  isNameVisible: boolean;
  useValidation: boolean;
  specialPromo?: IAdminGetSpecialPromoSchema;
  substitutiveJkList?: ISubstitutiveJKSchema[];
  logoHeader: IImage;
  promoText?: string;
  jkId?: number;
  userId?: number;
}

export const formatBannerList = (bannerList: IBanner[], builderJks: IJkForList[]) =>
  bannerList.map(({ buildingId, ...banner }) => ({
    ...banner,
    buildingId: buildingId && builderJks.some(jk => jk.id === buildingId) ? buildingId : undefined,
  }));

export const mapSettingsOfficeIsEmpty = (settings?: Partial<IMapSettingsOffice>) =>
  !settings || !settings.lat || !settings.lng || !settings.zoom;

export const builderInfoIsEmpty = (info: IBuilderInfo) =>
  typeof info.html !== 'string' && typeof info.title !== 'string';

const officeListIsEmpty = (officeList?: IOffice[]) => !officeList || !officeList.length;

export const formIsValid = (state: IFormState) => {
  const { info, mapSettingsJk, bannerList, officeList } = state;

  return [
    // Основные настройки
    !!state.name,
    !!state.nameGenitive,
    colorHexIsValid(state.colorHex),
    !!state.textShowOffers,
    imageIsValid(state.logoSmall),
    imageIsValid(state.logoHeader),
    imageIsValid(state.pinImage),
    imageIsValid(state.logoApp),
    // Настройки баннеров
    imageIsValid(state.logoBig),
    colorHexIsValid(state.textColorHex),
    bannerList.map(bannerIsValid).reduce(containsOnlyTrue),
    // Настройки карты
    !!mapSettingsJk.lat,
    !!mapSettingsJk.lng,
    !!mapSettingsJk.zoom,
    // Офисы
    officeList ? officeList.map(officeIsValid).reduce(containsOnlyTrue) : true,
    // Информация о застройщике
    builderInfoIsEmpty(info) || (!!info.html && !!info.title),
  ].reduce(containsOnlyTrue);
};

export const getInitialState = (props: IFormProps): IFormState => {
  const { builderJks, builders, specialPromo, competitiveJks } = props;

  return {
    disclaimer: specialPromo ? specialPromo.disclaimer : '',
    bannerList: specialPromo ? formatBannerList(specialPromo.bannerList, builderJks) : [EMPTY_BANNER],
    builderId: specialPromo ? specialPromo.builderId : builders[0].id,
    builderJks,
    colorHex: specialPromo ? specialPromo.colorHex : DEFAULT_COLOR_HEX,
    competitiveJkList: specialPromo ? specialPromo.competitiveJkList : [],
    competitiveJks,
    id: specialPromo ? specialPromo.id : undefined,
    info: specialPromo && !builderInfoIsEmpty(specialPromo.info) ? specialPromo.info : {},
    isNameVisible: specialPromo ? specialPromo.isNameVisible : true,
    jkList: specialPromo ? specialPromo.jkList : [],
    logoApp: (specialPromo && specialPromo.logoApp) || {},
    logoBig: specialPromo ? specialPromo.logoBig : {},
    logoHeader: specialPromo ? specialPromo.logoHeader : {},
    logoMobile: (specialPromo && specialPromo.logoMobile) || {},
    logoSmall: specialPromo ? specialPromo.logoSmall : {},
    mapSettingsJk: specialPromo ? specialPromo.mapSettingsJk : EMPTY_MAP_SETTINGS,
    mapSettingsOffice:
      specialPromo && !mapSettingsOfficeIsEmpty(specialPromo.mapSettingsOffice)
        ? (specialPromo.mapSettingsOffice as IMapSettingsOffice)
        : undefined,
    name: specialPromo ? specialPromo.name : '',
    nameGenitive: specialPromo ? specialPromo.nameGenitive : '',
    officeList: specialPromo && !officeListIsEmpty(specialPromo.officeList) ? specialPromo.officeList : undefined,
    pinImage: specialPromo ? specialPromo.pinImage : {},
    promoText: specialPromo ? specialPromo.promoText : '',
    regionList: specialPromo ? specialPromo.regionList : DEFAULT_SELECTED_REGIONS,
    status: specialPromo ? (specialPromo.status as TSpecialPromoStatus) : SpecialPromoStatusTypes.Draft,
    substitutiveJkList: specialPromo ? specialPromo.substitutiveJkList : undefined,
    textColorHex: specialPromo ? specialPromo.textColorHex : DEFAULT_TEXT_COLOR_HEX,
    textShowOffers: specialPromo ? specialPromo.textShowOffers : '',
    useValidation: false,
    jkId: specialPromo ? specialPromo.jkId : 0,
    userId: specialPromo ? specialPromo.userId : 0,
  };
};

export const formatFormStateToSpecialPromo = (
  state: IFormState,
): IAdminUpdateSpecialPromoRequest | IAdminCreateSpecialPromoRequest => ({
  accountId: state.builderId,
  bannerList: state.bannerList as ICreateBannerSchema[],
  builderId: state.builderId,
  colorHex: state.colorHex,
  competitiveJkList: state.competitiveJkList,
  id: state.id as number,
  info: state.info,
  isNameVisible: state.isNameVisible,
  jkList: state.jkList,
  logoApp: state.logoApp,
  logoBig: state.logoBig,
  logoHeader: state.logoHeader,
  logoMobile: state.logoMobile,
  logoSmall: state.logoSmall,
  mapSettingsJk: state.mapSettingsJk,
  mapSettingsOffice: state.mapSettingsOffice,
  name: state.name,
  nameGenitive: state.nameGenitive,
  officeList: state.officeList,
  pinImage: state.pinImage,
  promoText: state.promoText,
  regionList: state.regionList,
  status: state.status as EStatus,
  substitutiveJkList: state.substitutiveJkList,
  textColorHex: state.textColorHex,
  textShowOffers: state.textShowOffers,
  disclaimer: state.disclaimer,
  jkId: state.jkId as number,
  userId: state.userId as number,
});

export const createJkLabelText = (jk: IJkForList, regions: IRegion[]) => {
  const jkRegion = regions.find(r => r.id === jk.newRegion);
  const regionName = jkRegion ? jkRegion.displayName : `Регион ${jk.newRegion}`;

  return `${jk.id}: ${jk.name}, (${regionName})`;
};
