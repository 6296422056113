import * as React from 'react';

import { mergeStyles } from '@cian/utils';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { IJkForList } from '../../../http/get-jks-by-builder';
import { IRegion } from '../../../http/get-regions';
import { createJkLabelText } from '../helpers';

import * as styles from './index.css';

export interface ISortableJkItemProps {
  label: string;
  onDelete(): void;
}

export interface ISortableJkListProps {
  jks: IJkForList[];
  regions: IRegion[];
  onDelete(jkId: number): void;
}

export const SortableJkItem = SortableElement<ISortableJkItemProps>(({ label, onDelete }: ISortableJkItemProps) => (
  <li {...mergeStyles('list-group-item', styles['sortable-item'])}>
    <span>{label}</span>
    <button className="btn btn-xs btn-danger float-end" onClick={onDelete}>
      <i className="bi-trash-fill" />
    </button>
  </li>
));

export const SortableJkList = SortableContainer<ISortableJkListProps>(
  ({ jks, regions, onDelete }: ISortableJkListProps) => (
    <div className="row">
      <ul {...mergeStyles('col-md-8', styles['sortable-list'])}>
        {jks.map((jk, index) => (
          <SortableJkItem
            label={createJkLabelText(jk, regions)}
            key={jk.id}
            index={index}
            onDelete={() => onDelete(jk.id)}
          />
        ))}
      </ul>
    </div>
  ),
);
