import * as React from 'react';

import { EMPTY_OFFICE, IMapSettingsOffice, IOffice } from '../../../common/interfaces';
import { CoordinateField } from '../../coordinate-field';
import { NumberField } from '../../number-field/index';
import { FormPanel } from '../form-panel';
import { OfficeTab } from '../office-tab';

interface IOfficePanelProps {
  useValidation: boolean;
  officeList?: IOffice[];
  mapSettingsOffice?: IMapSettingsOffice;
  onMapSettingsOfficeChange(value?: IMapSettingsOffice): void;
  onOfficeListChange(value?: IOffice[]): void;
}

interface IOfficePanelState {
  visibleOfficeIndex?: number;
}

const validateZoomValue = (value?: number) => (value && value > 255 ? 255 : value);

export class OfficePanel extends React.Component<IOfficePanelProps, IOfficePanelState> {
  public constructor(props: IOfficePanelProps) {
    super(props);

    this.state = {};
  }

  public render() {
    const { mapSettingsOffice, onMapSettingsOfficeChange, officeList, useValidation } = this.props;
    const { visibleOfficeIndex } = this.state;

    if (!officeList || !mapSettingsOffice) {
      return (
        <div className="form-group mb-3">
          <button className="btn btn-success" onClick={this.addPanel}>
            <i className="bi-plus" /> Добавить блок с офисами
          </button>
        </div>
      );
    }

    const removePanelButton = (
      <button className="btn btn-xs btn-danger float-end" onClick={this.removePanel}>
        Удалить блок
      </button>
    );

    return (
      <FormPanel title="Блок с офисами (необязательно)" headButton={removePanelButton}>
        <div className="row">
          <NumberField
            label="Масштаб карты"
            className="col-md-6 form-group"
            value={mapSettingsOffice.zoom}
            onChange={value => onMapSettingsOfficeChange({ ...mapSettingsOffice, zoom: validateZoomValue(value) || 1 })}
            placeholder="от 1 до 255"
            minValue={1}
            maxValue={255}
            disableFractional
          />
          <CoordinateField
            label="Координаты центра карты"
            className="col-md-6 form-group mb-3"
            onChange={value => onMapSettingsOfficeChange({ ...mapSettingsOffice, ...value })}
            value={{ lat: mapSettingsOffice.lat, lng: mapSettingsOffice.lng }}
          />
        </div>
        <div className="panel-group">
          {officeList.map((office, index) => (
            <OfficeTab
              useValidation={useValidation}
              office={office}
              indexNumber={index + 1}
              key={index}
              active={index === visibleOfficeIndex}
              onToggle={() => this.handleToggleOfficeTab(index)}
              onDelete={() => this.handleDeleteOffice(index)}
              onChange={newOffice => this.handleOfficeChange(newOffice, index)}
            />
          ))}
        </div>
        <button className="btn btn-success" onClick={this.handleAddOffice}>
          Добавить офис
        </button>
      </FormPanel>
    );
  }

  private addPanel = () => {
    this.props.onOfficeListChange([EMPTY_OFFICE]);
    this.props.onMapSettingsOfficeChange({} as IMapSettingsOffice);
  };

  private removePanel = () => {
    this.props.onMapSettingsOfficeChange();
    this.props.onOfficeListChange();
  };

  private handleAddOffice = () => {
    const { officeList, onOfficeListChange } = this.props;

    if (officeList) {
      onOfficeListChange([...officeList, EMPTY_OFFICE]);
      this.setState({ visibleOfficeIndex: officeList.length });
    }
  };

  private handleDeleteOffice(index: number) {
    const { officeList, onOfficeListChange } = this.props;

    if (officeList) {
      const newOfficeList = officeList.filter((office, i) => i !== index);

      if (!newOfficeList.length) {
        this.removePanel();
      } else {
        onOfficeListChange(newOfficeList);
      }
    }
  }

  private handleToggleOfficeTab(index: number) {
    this.setState(state => ({
      visibleOfficeIndex: index !== state.visibleOfficeIndex ? index : undefined,
    }));
  }

  private handleOfficeChange(newOffice: IOffice, index: number) {
    const { officeList, onOfficeListChange } = this.props;

    if (officeList) {
      const newOfficeList = officeList.map((office, idx) => {
        return idx === index ? newOffice : { ...office, isMain: newOffice.isMain ? false : office.isMain };
      });
      onOfficeListChange(newOfficeList);
    }
  }
}
