import { fetchGetNewobjectsByRegions } from '../repositories/special-promos/v1/get-newobjects-by-regions';
import { IHttpApi } from '@cian/http-api/shared';
import { IJkForList } from './get-jks-by-builder';

interface IGetJksByRegionsResponse {
  data: {
    newobjects: IJkForList[];
  };
}

export async function getJksByRegions(httpApi: IHttpApi, regionIds: number[], excludeBuilderId: number) {
  try {
    const { response, statusCode } = await fetchGetNewobjectsByRegions({
      httpApi,
      parameters: { regionIds, excludeBuilderId },
    });

    if (statusCode !== 200 || (response && 'errors' in response)) {
      return [];
    }

    return (response as IGetJksByRegionsResponse).data.newobjects;
  } catch (error) {
    return [];
  }
}
