import * as React from 'react';
import { arrayMove, SortEnd } from 'react-sortable-hoc';

import { mergeStyles } from '@cian/utils';

import { ISubstitutiveJk } from '../../../common/interfaces';
import { IJkForList } from '../../../http/get-jks-by-builder';
import { IRegion } from '../../../http/get-regions';
import { DropdownSearchField } from '../../dropdown-search';
import { createJkLabelText } from '../helpers';
import { SortableJkList } from '../sortable-list';

import * as styles from './index.css';

export interface ISubstitutiveJkListProps {
  regions: IRegion[];
  builderJks: IJkForList[];
  substitutiveJkList: ISubstitutiveJk[];
  onChange(value: ISubstitutiveJk[]): void;
}

export interface ISubstitutiveJkListState {
  selectedJkId?: number;
}

export class SubstitutiveJkList extends React.Component<ISubstitutiveJkListProps, ISubstitutiveJkListState> {
  public state: ISubstitutiveJkListState = {};

  public render() {
    const { substitutiveJkList, regions } = this.props;

    return (
      <div>
        <div {...mergeStyles('row', styles['dropdown-container'])}>
          <DropdownSearchField
            label="ЖК выбранного застройщика в регионе конкурентного ЖК"
            value={this.state.selectedJkId || 0}
            values={this.getDropdownValues()}
            onChange={(value: number) => this.setState({ selectedJkId: value })}
            className="col-md-6 col-sm-8 form-group"
          />
          <div className="col-md-6 col-sm-4 form-group">
            <button className="btn btn-success" onClick={this.handleAddJk} disabled={!this.state.selectedJkId}>
              Добавить
            </button>
          </div>
        </div>
        {!!substitutiveJkList.length && (
          <SortableJkList
            regions={regions}
            jks={this.getSortedJks()}
            onSortEnd={this.handleSortEnd}
            onDelete={this.handleDeleteJk}
          />
        )}
      </div>
    );
  }

  private handleSortEnd = (result: SortEnd) => {
    const { oldIndex, newIndex } = result;
    const { onChange, substitutiveJkList } = this.props;

    onChange(arrayMove(substitutiveJkList, oldIndex, newIndex));
  };

  private getSortedJks = () => {
    const { builderJks, substitutiveJkList } = this.props;

    return substitutiveJkList
      .map(substitutiveJk => builderJks.find(jk => jk.id === substitutiveJk.id))
      .filter(jk => jk !== undefined) as IJkForList[];
  };

  private getDropdownValues = () => {
    const { substitutiveJkList, builderJks, regions } = this.props;

    return builderJks
      .filter(competitiveJk => !substitutiveJkList.some(jk => jk.id === competitiveJk.id))
      .map(jk => ({
        label: createJkLabelText(jk, regions),
        value: jk.id,
      }));
  };

  private handleDeleteJk = (competitiveJkId: number) => {
    const { onChange, substitutiveJkList } = this.props;

    onChange(substitutiveJkList.filter(jk => jk.id !== competitiveJkId));
  };

  private handleAddJk = () => {
    const { selectedJkId } = this.state;
    const { onChange, builderJks, substitutiveJkList } = this.props;

    const selectedJk = selectedJkId && builderJks.find(jk => jk.id === selectedJkId);
    if (!selectedJk) {
      return;
    }

    onChange(substitutiveJkList.concat({ id: selectedJk.id, regionId: selectedJk.newRegion }));

    this.setState({ selectedJkId: undefined });
  };
}
