import { IConfigFeatures } from '../../types/features';
import { ITypedReduxAction } from '../../types/redux/actionType';
import { actionGenerator } from '../../utils/redux/actionGenerator';

export enum EDemoActionTypes {
  SetFeatures = 'DEMO/SET_DEMO',
}

export type TSetFeatures = ITypedReduxAction<EDemoActionTypes.SetFeatures, IConfigFeatures>;

/**
 * Redux action, который записывает в store фичи
 * @param features IConfigFeatures
 */
export const setFeatures: (features: IConfigFeatures) => TSetFeatures = actionGenerator<
  EDemoActionTypes.SetFeatures,
  IConfigFeatures
>(EDemoActionTypes.SetFeatures);
