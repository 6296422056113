import * as React from 'react';

import { colorHexIsValid, imageIsValid } from '../../../common/helpers';
import { EMPTY_BANNER, IBanner } from '../../../common/interfaces';
import { IJkForList } from '../../../http/get-jks-by-builder';
import { IRegion } from '../../../http/get-regions';
import { IImage, ImageSelect } from '../../image-select';
import { BannerTab } from '../banner-tab';
import { ColorSelect } from '../color-select';
import { FormPanel } from '../form-panel';
import { createImageLabel, IMAGE_LABEL_TYPE } from '../image_tooltips';

interface IBannerPanelProps {
  textColorHex: string;
  logoBig: IImage;
  bannerList: IBanner[];
  builderJks: IJkForList[];
  regions: IRegion[];
  onTextColorChange(value: string): void;
  onLogoChange(value: IImage): void;
  onBannerListChange(value: IBanner[]): void;
  useValidation: boolean;
}

interface IBannerPanelState {
  bannerIndexShow?: number;
}

export class BannerPanel extends React.Component<IBannerPanelProps, IBannerPanelState> {
  public constructor(props: IBannerPanelProps) {
    super(props);

    this.state = {};
  }

  public render() {
    const { textColorHex, logoBig, bannerList, onLogoChange, onTextColorChange, useValidation, builderJks, regions } =
      this.props;
    const { bannerIndexShow } = this.state;

    return (
      <FormPanel title="Блок с баннерами">
        <div className="row">
          <div className="col-md-6 form-group">
            <label>Цвет текста кнопки</label>
            <ColorSelect
              invalid={useValidation && !colorHexIsValid(textColorHex)}
              colorHex={textColorHex}
              onChange={onTextColorChange}
            />
          </div>
          <div className="col-md-6 form-group">
            {createImageLabel(IMAGE_LABEL_TYPE.logoBig)}
            <ImageSelect
              invalid={useValidation && !imageIsValid(logoBig)}
              value={logoBig}
              onChange={onLogoChange}
              minWidth={1}
              minHeight={108}
              previewWidth={500}
              previewHeight={54}
              inlinePreview
            />
          </div>
        </div>
        <div className="panel-group">
          {bannerList.map((banner, index) => (
            <BannerTab
              builderJks={builderJks}
              regions={regions}
              useValidation={useValidation}
              banner={banner}
              indexNumber={index + 1}
              key={index}
              active={index === bannerIndexShow}
              showDeleteButton={bannerList.length > 1}
              onToggle={() => this.handleToggleBannerTab(index)}
              onDelete={() => this.handleDeleteBanner(index)}
              onChange={newBanner => this.handleBannerChange(newBanner, index)}
            />
          ))}
        </div>
        <button className="btn btn-success" onClick={this.handleAddBanner}>
          Добавить баннер
        </button>
      </FormPanel>
    );
  }

  private handleAddBanner = () => {
    const { bannerList, onBannerListChange } = this.props;

    onBannerListChange([...bannerList, EMPTY_BANNER]);
    this.setState({ bannerIndexShow: bannerList.length });
  };

  private handleDeleteBanner(index: number) {
    const { bannerList, onBannerListChange } = this.props;

    onBannerListChange(bannerList.filter((banner, i) => i !== index));
  }

  private handleToggleBannerTab(index: number) {
    this.setState(state => ({
      bannerIndexShow: index !== state.bannerIndexShow ? index : undefined,
    }));
  }

  private handleBannerChange(newBanner: IBanner, index: number) {
    const { bannerList, onBannerListChange } = this.props;
    const newBannerList = bannerList.map((banner, i) => (i === index ? newBanner : banner));

    onBannerListChange(newBannerList);
  }
}
