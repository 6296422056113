import { IImage } from '../components/image-select';

export type TSpecialPromoStatus = 'created' | 'draft' | 'published' | 'deleted' | 'preview';

export const SpecialPromoStatusTypes = {
  Created: 'created' as TSpecialPromoStatus,
  Deleted: 'deleted' as TSpecialPromoStatus,
  Draft: 'draft' as TSpecialPromoStatus,
  Preview: 'preview' as TSpecialPromoStatus,
  Published: 'published' as TSpecialPromoStatus,
  Empty: '' as TSpecialPromoStatus,
};

export const specialPromoStatusNames = {
  [SpecialPromoStatusTypes.Created]: 'Создан',
  [SpecialPromoStatusTypes.Deleted]: 'Удален',
  [SpecialPromoStatusTypes.Draft]: 'Черновик',
  [SpecialPromoStatusTypes.Published]: 'Опубликован',
  [SpecialPromoStatusTypes.Preview]: 'Предпросмотр',
};

export const specialPromoStatusDropdownValues = [
  { value: SpecialPromoStatusTypes.Published, label: 'Опубликован' },
  { value: SpecialPromoStatusTypes.Draft, label: 'Черновик' },
  { value: SpecialPromoStatusTypes.Deleted, label: 'Удален' },
  { value: SpecialPromoStatusTypes.Preview, label: 'Предпросмотр' },
];

export interface IBanner {
  image: IImage;
  text: string;
  textButton: string;
  urlLink: string;
  buildingId?: number;
  id?: number;
  disclaimer: string;
}

export const EMPTY_BANNER = {
  image: {},
  text: '',
  textButton: '',
  urlLink: '',
  disclaimer: '',
};

export interface IOffice {
  id?: number;
  address: string;
  isMain: boolean;
  mapLat: number;
  mapLng: number;
  name: string;
  phone: string;
}

export interface IMapSettingsOffice {
  lat: number;
  lng: number;
  zoom: number;
}

export const EMPTY_OFFICE: IOffice = {
  address: '',
  isMain: false,
  mapLat: 0,
  mapLng: 0,
  name: '',
  phone: '',
};

export interface IMapSettingsJk {
  lat: number;
  lng: number;
  zoom: number;
}

export const EMPTY_MAP_SETTINGS = {
  lat: 0,
  lng: 0,
  zoom: 0,
};

export interface IBuilderInfo {
  html?: string;
  title?: string;
  text?: string;
}

export const pageSizeValues = [20, 50, 100];
export const pageSizeDropdownValues = pageSizeValues.map(pageSize => ({
  label: pageSize.toString(),
  value: pageSize.toString(),
}));

export interface IFilter {
  id?: number;
  region?: number;
  status?: TSpecialPromoStatus;
  createdFrom?: string;
  createdTo?: string;
  p?: number;
  pageSize: number | '';
}

export interface ISpecialPromoLog {
  id: number;
  status: TSpecialPromoStatus;
  statusDate: string;
  userEmail: string;
}

export interface ISubstitutiveJk {
  id: number;
  regionId: number;
}

export interface ICompetitiveJk extends ISubstitutiveJk {
  substitutiveJkList: ISubstitutiveJk[];
}
