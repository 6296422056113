import * as React from 'react';

import { helperIcon } from './helper_icon';

import * as styles from './index.css';

interface IInfoTooltipProps {
  content: JSX.Element;
}

const Tooltip = ({ content }: IInfoTooltipProps) => (
  <span className={styles['container']}>
    {helperIcon}
    <div className={styles['tooltip']}>{content}</div>
  </span>
);

type TImageLabel = 'logoBig' | 'logoSmall' | 'logoHeader' | 'pinImage' | 'logoMobile' | 'banner' | 'logoApp';

export const IMAGE_LABEL_TYPE: { [k: string]: TImageLabel } = {
  banner: 'banner',
  logoApp: 'logoApp',
  logoBig: 'logoBig',
  logoHeader: 'logoHeader',
  logoMobile: 'logoMobile',
  logoSmall: 'logoSmall',
  pinImage: 'pinImage',
};

const requiredMark = <span className="text-danger">*</span>;
const exampleText = <div className={styles['example-text']}>(пример расположения изображения)</div>;

const logoSmallTooltipContent = (
  <div>
    Используется в шапке десктопного сайта
    {exampleText}
    <div className={styles['logo_small_1']} />
    Используется над картой:
    <div> - на карточке квартиры с привязкой к ЖК</div>
    <div> - на странице ЖК</div>
    {exampleText}
    <div className={styles['logo_small_2']} />
  </div>
);

const logoHeaderTooltipContent = (
  <div>
    Используется в шапке, появляющейся при прокрутке лендинговой страницы на десктопном сайте
    {exampleText}
    <div className={styles['logo_header']} />
  </div>
);

const pinImageTooltipContent = (
  <div>
    Используется для пинов ЖК застройщика на карте и пинов офисов продаж застройщика на карте в лендинге спецпроекта
    {exampleText}
    <div className={styles['pin_image']} />
  </div>
);

const logoMobileTooltipContent = (
  <div>
    Используется в ссылке на спецпроект в шторке меню в мобильной версии сайта
    {exampleText}
    <div className={styles['logo_mobile']} />
  </div>
);

const logoBigTooltipContent = (
  <div>
    Используется на странице лендинга спецпроекта, в баннерном блоке на каждом изображении. Обычно с прозрачным фоном
    {exampleText}
    <div className={styles['logo_big']} />
  </div>
);

const bannerTooltipContent = (
  <div>
    Используется как фон баннера в карусели баннеров вверху страницы на лендинге спецпроекта.
    {exampleText}
    <div className={styles['banner']} />
  </div>
);

const logoAppTooltipContent = (
  <div>
    Используется в мобильных приложениях на странице лендинга спецпроекта.
    {exampleText}
    <div className={styles['logo_app']} />
  </div>
);

export function createImageLabel(type: TImageLabel) {
  switch (type) {
    case IMAGE_LABEL_TYPE.logoBig:
      return (
        <label>
          Логотип для баннеров {requiredMark} <Tooltip content={logoBigTooltipContent} />
        </label>
      );
    case IMAGE_LABEL_TYPE.logoSmall:
      return (
        <label>
          Лого в меню и ссылки над картой {requiredMark} <Tooltip content={logoSmallTooltipContent} />
        </label>
      );
    case IMAGE_LABEL_TYPE.logoHeader:
      return (
        <label>
          Лого в хедере лендинга {requiredMark} <Tooltip content={logoHeaderTooltipContent} />
        </label>
      );
    case IMAGE_LABEL_TYPE.pinImage:
      return (
        <label>
          Изображение пина для карты {requiredMark} <Tooltip content={pinImageTooltipContent} />
        </label>
      );
    case IMAGE_LABEL_TYPE.logoMobile:
      return (
        <label>
          Лого в мобильном меню <Tooltip content={logoMobileTooltipContent} />
        </label>
      );
    case IMAGE_LABEL_TYPE.banner:
      return (
        <label>
          Изображение баннера {requiredMark} <Tooltip content={bannerTooltipContent} />
        </label>
      );
    case IMAGE_LABEL_TYPE.logoApp:
      return (
        <label>
          Логотип для мобильных приложений (только svg) {requiredMark} <Tooltip content={logoAppTooltipContent} />
        </label>
      );
    default:
      return <label />;
  }
}
