import * as React from 'react';
import * as styles from './index.css';

import { mergeStyles } from '@cian/utils/lib/shared/style';

interface IProps {
  colorHex: string;
  onChange(colorHex: string): void;
  invalid?: boolean;
}

export const ColorSelect = (props: IProps) => {
  const { colorHex, onChange, invalid } = props;

  return (
    <div className={styles['container']}>
      <input
        value={colorHex}
        {...mergeStyles('form-control', invalid && styles['invalid'])}
        type="text"
        maxLength={7}
        onChange={e => onChange(e.currentTarget.value)}
      />
      <input
        type="color"
        title="Выбрать цвет"
        className={styles['color-picker']}
        value={colorHex}
        onChange={e => onChange(e.currentTarget.value)}
      />
    </div>
  );
};
