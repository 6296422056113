import { IHttpApi } from '@cian/http-api/shared';

export interface IJkForList {
  id: number;
  name: string;
  newRegion: number;
}

interface IGetJksByBuilderResponse {
  data: {
    newobjects: IJkForList[];
  };
}

export async function getJksByBuilder(httpApi: IHttpApi, builderId: number, gkCount?: number) {
  const { response, statusCode } = await httpApi.fetch({
    apiType: 'public',
    assertStatusCodes: [200, 400],
    method: 'GET',
    microserviceName: 'special-promos',
    pathApi: '/v1/get-newobject-names-by-builder-id/',
    parameters: { builderId, count: gkCount || 9999 },
  });

  if (statusCode !== 200 || (response && 'errors' in response)) {
    throw response;
  }

  return (response as IGetJksByBuilderResponse).data.newobjects;
}
