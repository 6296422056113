import { IImage } from '../components/image-select';
import { IDropdownValue } from '../components/select-field';
import { IBanner, IFilter, IOffice } from './interfaces';

export const getQueryStringByFilter = (filter: IFilter): string =>
  [
    filter.region && `region=${filter.region}`,
    filter.id && `id=${filter.id}`,
    filter.status !== undefined && `status=${filter.status}`,
    filter.createdFrom && `createdFrom=${filter.createdFrom}`,
    filter.createdTo && `createdTo=${filter.createdTo}`,
    filter.p && `p=${filter.p}`,
    `pageSize=${filter.pageSize}`,
  ]
    .filter(Boolean)
    .join('&');

export const colorHexIsValid = (color: string) => /^#[0-9a-f]{6}$/i.test(color);

export const imageIsValid = (image: IImage) => !!image.base64 || !!image.url;

export const bannerIsValid = (banner: IBanner) =>
  imageIsValid(banner.image) && !!banner.text && !!banner.textButton && !!banner.urlLink;

export const containsOnlyTrue = (prev: boolean, next: boolean) => prev && next;

export const officeIsValid = (office: IOffice) => {
  const { name, address, phone, mapLat, mapLng } = office;

  return !!name && !!address && !!phone && !!mapLat && !!mapLng;
};

export const formatCreateUpdateDate = (dateString: string): string =>
  `${dateString.slice(0, 10)} ${dateString.slice(11, 19)}`;

export const SPB_LO_REGION = { value: -2, label: 'Санкт-Петербург и ЛО' };

export const getFilteredDropdownValues = (values: IDropdownValue[], search: string) => {
  if (!search) {
    return values;
  }

  const pattern = new RegExp(search, 'ig');

  return values.filter(value => pattern.test(value.label));
};
