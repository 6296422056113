import { IHttpApi } from '@cian/http-api/shared';

import { IFilter, TSpecialPromoStatus } from '../common/interfaces';

export interface ISpecialPromoForList {
  id: number;
  name: string;
  regionList: number[];
  created: string;
  updated: string;
  status: TSpecialPromoStatus;
  builderId: number;
}

export async function getSpecialPromoList(httpApi: IHttpApi, filter: IFilter) {
  const { response, statusCode } = await httpApi.fetch({
    apiType: 'public',
    assertStatusCodes: [200, 400],
    method: 'GET',
    microserviceName: 'special-promos',
    pathApi: '/v1/admin-get-special-promo-list/',
    parameters: {
      ...filter,
    },
  });

  if (statusCode !== 200 || (response && 'errors' in response)) {
    throw response;
  }

  return response;
}
