import { combineReducers, compose, createStore as _createStore, StoreEnhancer } from 'redux';
import { demoReducer } from '../../reducers';
import { IApplicationState, TReduxActions, TReduxStore } from '../../types/redux';

let storeReminder: TReduxStore;

/**
 * Хелпер для создания Redux стора
 * @param initialState IApplicationState - предзагруженное состояние
 */
export function createReduxStore(initialState: IApplicationState, debugEnabled: boolean) {
  const reducers = combineReducers<IApplicationState, TReduxActions>({
    demo: demoReducer,
  });

  const composeEnhancers = debugEnabled ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

  const storeEnchancers = composeEnhancers() as StoreEnhancer<IApplicationState>;

  const createStore = (): TReduxStore =>
    _createStore<IApplicationState, TReduxActions, IApplicationState, {}>(reducers, initialState, storeEnchancers);

  if (process.env.NODE_ENV === 'development' && debugEnabled) {
    if (!storeReminder) {
      storeReminder = createStore();
    }

    storeReminder.replaceReducer(reducers);

    return storeReminder;
  }

  return createStore();
}
