import * as React from 'react';

import { formatCreateUpdateDate } from '../../../common/helpers';
import { ISpecialPromoLog, specialPromoStatusNames } from '../../../common/interfaces';

interface IHistoryProps {
  history?: ISpecialPromoLog[];
}

export const SpecialPromoHistory = (props: IHistoryProps) => {
  if (!props.history) {
    return null;
  }

  return (
    <div className="panel panel-primary">
      <div className="panel-heading">История изменений</div>
      <div className="panel-body">
        <table className="table table-striped">
          <tbody>
            <tr>
              <th>Дата изменения</th>
              <th>Пользователь</th>
              <th>Статус</th>
            </tr>
            {props.history.map(log => (
              <tr key={log.id}>
                <td>{formatCreateUpdateDate(log.statusDate)}</td>
                <td>{log.userEmail}</td>
                <td>{specialPromoStatusNames[log.status]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
