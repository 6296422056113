import * as React from 'react';

import { mergeStyles } from '@cian/utils/lib/shared/style';

import { formatCreateUpdateDate, SPB_LO_REGION } from '../../common/helpers';
import { specialPromoStatusNames } from '../../common/interfaces';
import { IRegion } from '../../http/get-regions';
import { ISpecialPromoForList } from '../../http/get-special-promo-list';
import { SPECIAL_PROMOS_ROUTE } from '../../routes';

import * as styles from './index.css';

interface ISpecialPromoTableProps {
  onDelete(id: number): void;
  specialPromos: ISpecialPromoForList[];
  regions: IRegion[];
}

const getRegionNamesByIdList = (regionList: number[], regions: IRegion[]) =>
  regionList
    .map(regionId => {
      if (regionId === SPB_LO_REGION.value) {
        return SPB_LO_REGION.label;
      }

      const currentRegion = regions.find(region => region.id === regionId);

      return currentRegion ? currentRegion.displayName : regionId;
    })
    .join(', ');

export const SpecialPromoTable = (props: ISpecialPromoTableProps) => {
  const { specialPromos, regions } = props;

  return (
    <table {...mergeStyles('table table-striped table-hover table-fixed', styles['table'])}>
      <thead>
        <tr>
          <th>id</th>
          <th>Название</th>
          <th>Регионы</th>
          <th>Добавлен</th>
          <th>Изменен</th>
          <th>Статус</th>
          <th>ИД застройщика</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {specialPromos.map(specialPromo => (
          <tr key={specialPromo.id}>
            <td>{specialPromo.id}</td>
            <td>{specialPromo.name}</td>
            <td>{getRegionNamesByIdList(specialPromo.regionList, regions)}</td>
            <td>{formatCreateUpdateDate(specialPromo.created)}</td>
            <td>{formatCreateUpdateDate(specialPromo.updated)}</td>
            <td>{specialPromoStatusNames[specialPromo.status]}</td>
            <td>{specialPromo.builderId}</td>
            <td>
              <a
                className={`btn btn-primary btn-sm ${styles['m-r-5']}`}
                title="Редактировать"
                href={`${SPECIAL_PROMOS_ROUTE}${specialPromo.id}/`}
              >
                <i className="bi-pencil-fill" />
              </a>
              <button className="btn btn-danger btn-sm" title="Удалить" onClick={() => props.onDelete(specialPromo.id)}>
                <i className="bi-trash-fill" />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
