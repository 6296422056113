import * as React from 'react';

import { NumberField } from '../number-field';

import { getQueryStringByFilter } from '../../common/helpers';
import { IFilter, specialPromoStatusDropdownValues, TSpecialPromoStatus } from '../../common/interfaces';
import { IRegion } from '../../http/get-regions';
import { SPECIAL_PROMOS_ROUTE } from '../../routes';
import { SelectField } from '../select-field';

import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import { formatDateForDatePicker } from '../../common/date-picker';

interface IProps {
  filter: IFilter;
  onChange(value: IFilter): void;
  regions: IRegion[];
}

export const SpecialPromoFilter = (props: IProps) => {
  const { filter, onChange, regions } = props;
  const regionDropdownValues = regions.map(region => ({ value: region.id, label: region.displayName }));

  return (
    <div className="card mt-4 mb-4">
      <div className="card-body">
        <div className="row">
          <NumberField
            value={filter.id}
            onChange={(value?: number) => onChange({ ...filter, id: value })}
            minValue={0}
            disableFractional
            className="form-group col-md-1 col-sm-2"
            placeholder="id"
          />
          <SelectField
            className="form-group col-md-3 col-sm-5"
            onChange={value => onChange({ ...filter, region: Number(value) || undefined })}
            value={filter.region || ''}
            values={regionDropdownValues}
            emptyText="- любой регион -"
            canEmpty
          />
          <SelectField
            className="form-group col-md-2 col-sm-5"
            onChange={(value?: TSpecialPromoStatus) => onChange({ ...filter, status: value })}
            value={filter.status || ''}
            values={specialPromoStatusDropdownValues}
            emptyText="- любой статус -"
            canEmpty
          />
          <div className="form-group col-md-2 col-sm-5 col-xs-6">
            <DatePicker
              locale={ru}
              className="form-control"
              dateFormat="dd.MM.yyyy"
              placeholderText="Добавлен от"
              selected={formatDateForDatePicker(filter.createdFrom)}
              onChange={date => onChange({ ...filter, createdFrom: date?.toLocaleDateString('ru-Ru') })}
            />
          </div>
          <div className="form-group col-md-2 col-sm-5 col-xs-6">
            <DatePicker
              locale={ru}
              className="form-control"
              dateFormat="dd.MM.yyyy"
              placeholderText="Добавлен до"
              selected={formatDateForDatePicker(filter.createdTo)}
              onChange={date => onChange({ ...filter, createdTo: date?.toLocaleDateString('ru-Ru') })}
            />
          </div>
          <div className="form-group col-md-2 col-sm-2">
            <a
              className="btn btn-primary float-end"
              href={`${SPECIAL_PROMOS_ROUTE}?${getQueryStringByFilter({ ...filter, p: 1 })}`}
            >
              Обновить
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
