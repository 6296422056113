import { IHttpApi } from '@cian/http-api/shared';
import {
  fetchAdminUpdateSpecialPromo,
  IAdminUpdateSpecialPromoRequest,
} from '../repositories/special-promos/v3/admin-update-special-promo';

export async function updateSpecialPromo(httpApi: IHttpApi, specialPromo: IAdminUpdateSpecialPromoRequest) {
  const { response, statusCode } = await fetchAdminUpdateSpecialPromo({
    httpApi,
    parameters: specialPromo,
    config: {
      requestConfig: {
        headers: [['Content-Type', 'application/x-www-form-urlencoded']],
      },
    },
  });

  if (statusCode !== 200 || (response && 'errors' in response)) {
    throw response;
  }

  return response;
}
