/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TAdminCreateSpecialPromoModel,
  IAdminCreateSpecialPromoRequest,
  IMappers,
  IAdminCreateSpecialPromoResponse,
  IAdminCreateSpecialPromoResponseError,
  TAdminCreateSpecialPromoResponse,
} from './types';

const defaultConfig: TAdminCreateSpecialPromoModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'special-promos',
  pathApi: '/v3/admin-create-special-promo/',
  hostType: 'api',
} as TAdminCreateSpecialPromoModel;

function createAdminCreateSpecialPromoModel(
  parameters: IAdminCreateSpecialPromoRequest,
): TAdminCreateSpecialPromoModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IAdminCreateSpecialPromoOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IAdminCreateSpecialPromoRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchAdminCreateSpecialPromo<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IAdminCreateSpecialPromoOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TAdminCreateSpecialPromoResponse
> {
  const { response, statusCode } = await httpApi.fetch(createAdminCreateSpecialPromoModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IAdminCreateSpecialPromoResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IAdminCreateSpecialPromoResponseError);
    }
  }

  return { response, statusCode } as TAdminCreateSpecialPromoResponse;
}

export { defaultConfig, createAdminCreateSpecialPromoModel, fetchAdminCreateSpecialPromo };
