import * as React from 'react';

import { Outside } from '@cian/ui-kit';
import { mergeStyles } from '@cian/utils/lib/shared/style';

import { getFilteredDropdownValues } from '../../common/helpers';
import { IDropdownValue } from '../select-field';

import * as styles from './index.css';

interface IDropdownSearchFieldProps {
  values: IDropdownValue[];
  value: number | string;
  hint?: string;
  onChange(value: number | string): void;
  className?: string;
  label?: string;
  required?: boolean;
  emptyValue?: number | string;
}

interface IDropdownSearchFieldState {
  dropdownIsOpen: boolean;
  search: string;
}

export class DropdownSearchField extends React.Component<IDropdownSearchFieldProps, IDropdownSearchFieldState> {
  public constructor(props: IDropdownSearchFieldProps) {
    super(props);

    this.state = {
      dropdownIsOpen: false,
      search: '',
    };
  }

  public render() {
    const { value, className, label, required, values, emptyValue, hint } = this.props;
    const { search, dropdownIsOpen } = this.state;

    const filteredValues = getFilteredDropdownValues(values, search);
    const withItems = filteredValues.length > 0;

    return (
      <div {...mergeStyles(className, styles['container'])}>
        {label && (
          <label>
            {label}&nbsp;
            {required && <span className="text-danger">*</span>}
          </label>
        )}
        <div className="dropdown">
          <Outside onOutside={() => this.handleClickOutside()}>
            <div>
              <button
                data-testid="dropdown-select-button"
                {...mergeStyles('btn btn-default', styles['dropdown-btn'])}
                onClick={() => this.setState({ dropdownIsOpen: !dropdownIsOpen })}
              >
                {this.getButtonText()}
                <span className="caret" />
              </button>
              {dropdownIsOpen && (
                <ul {...mergeStyles('dropdown-menu', styles['dropdown-menu'])}>
                  <li className={styles['search']}>
                    <input
                      type="text"
                      className="form-control"
                      onChange={e => this.setState({ search: e.currentTarget.value })}
                      placeholder="Поиск"
                    />
                  </li>
                  {emptyValue !== undefined && withItems && (
                    <li
                      {...mergeStyles(styles['list-item'], emptyValue === value && styles['active'])}
                      onClick={() => this.onSelectValue(emptyValue)}
                    >
                      &mdash;
                    </li>
                  )}
                  {filteredValues.map(val => (
                    <li
                      data-testid="dropdown-item"
                      key={val.value}
                      {...mergeStyles(styles['list-item'], val.value === value && styles['active'])}
                      onClick={() => this.onSelectValue(val.value)}
                    >
                      {val.label}
                    </li>
                  ))}
                  {!withItems && (
                    <li className={styles['list-item']}>{search ? 'По вашему запросу ничего не найдено' : 'Пусто'}</li>
                  )}
                </ul>
              )}
            </div>
          </Outside>
        </div>

        {hint && <div className={styles['hint']}>{hint}</div>}
      </div>
    );
  }

  private onSelectValue(value: string | number) {
    this.props.onChange(value);
    this.setState({ dropdownIsOpen: false });
  }

  private handleClickOutside() {
    if (this.state.dropdownIsOpen) {
      this.setState({ dropdownIsOpen: false });
    }
  }

  private getButtonText(): string {
    const selectedValue = this.props.values.find(v => v.value === this.props.value);

    return selectedValue ? selectedValue.label : 'Выбрать...';
  }
}
