import { mergeStyles } from '@cian/utils/lib/shared/style';
import * as React from 'react';
import * as styles from './index.css';

interface IActionButtons {
  onSubmit(): void;
  onCancel(): void;
  isDisabled: boolean;
  className?: string;
}

export const ActionButtons = (props: IActionButtons) => (
  <div className={props.className}>
    <button {...mergeStyles('btn btn-success', styles['m-r-10'])} onClick={props.onSubmit} disabled={props.isDisabled}>
      Сохранить
    </button>
    <button className="btn btn-danger" onClick={props.onCancel} disabled={props.isDisabled}>
      Закрыть
    </button>
  </div>
);
