import { IHttpApi } from '@cian/http-api/shared';
import { fetchAdminDeleteSpecialPromo } from '../repositories/special-promos/v1/admin-delete-special-promo';

export async function deleteSpecialPromo(httpApi: IHttpApi, id: number) {
  const { response, statusCode } = await fetchAdminDeleteSpecialPromo({
    httpApi,
    parameters: { id },
    config: {
      requestConfig: {
        headers: [['Content-Type', 'application/x-www-form-urlencoded']],
      },
    },
  });

  if (statusCode !== 200 || (response && 'errors' in response)) {
    throw response;
  }

  return response;
}
