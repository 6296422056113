/* eslint-disable max-lines */
import * as React from 'react';

import { colorHexIsValid, imageIsValid, SPB_LO_REGION } from '../../common/helpers';
import { specialPromoStatusDropdownValues, TSpecialPromoStatus } from '../../common/interfaces';
import { getJksByBuilder } from '../../http/get-jks-by-builder';
import { getJksByRegions } from '../../http/get-jks-by-regions';
import { EXCLUDED_REGIONS } from '../../http/get-regions';
import { DropdownSearchField } from '../dropdown-search';
import { ImageSelect } from '../image-select';
import { MultiselectorField } from '../multiselector-field';
import { NumberField } from '../number-field';
import { SelectField } from '../select-field';
import { TextField } from '../text-field';
import { ActionButtons } from './action-buttons';
import { BannerPanel } from './banner-panel';
import { BuilderInfoPanel } from './builder-info-panel';
import { ColorSelect } from './color-select';
import { CompetitiveJkPanel } from './competitive-jk-panel';
import { FormPanel } from './form-panel';
import { formatFormStateToSpecialPromo, formIsValid, getInitialState, IFormProps, IFormState } from './helpers';
import { SpecialPromoHistory } from './history';
import { createImageLabel, IMAGE_LABEL_TYPE } from './image_tooltips';
import { JksPanel } from './jks-panel';
import { MapSettingsPanel } from './map-settings-panel';
import { OfficePanel } from './office-panel';

import * as styles from './index.css';

export class SpecialPromoForm extends React.Component<IFormProps, IFormState> {
  private regionChangeInProcess = false;
  private builderChangeInProcess = false;

  public constructor(props: IFormProps) {
    super(props);

    this.state = getInitialState(props);
  }

  public render() {
    const { regions, builders, title, history } = this.props;
    const filteredRegions = regions.filter(region => !EXCLUDED_REGIONS.includes(region.id));
    const regionDropdownValues = [SPB_LO_REGION].concat(
      filteredRegions.map(region => ({ value: region.id, label: region.displayName })),
    );
    const {
      builderId,
      logoSmall,
      logoMobile,
      textShowOffers,
      pinImage,
      nameGenitive,
      regionList,
      colorHex,
      textColorHex,
      status,
      bannerList,
      logoBig,
      jkList,
      builderJks,
      mapSettingsOffice,
      officeList,
      mapSettingsJk,
      info,
      name,
      promoText,
      isNameVisible,
      useValidation,
      logoHeader,
      competitiveJks,
      competitiveJkList,
      logoApp,
      disclaimer,
      jkId,
      userId,
    } = this.state;

    return (
      <div className={styles['container']}>
        {this.renderActionButtons()}
        <h2>{title}</h2>
        <hr />
        <FormPanel title="Общие настройки спецпроекта">
          <div className="row">
            <div className="col-xs-12">
              <div className="row">
                <TextField
                  required
                  invalid={useValidation && !name}
                  value={name}
                  onChange={value => this.setState({ name: value })}
                  containerClass="col-sm-6 mb-3"
                  label="Название компании"
                  maxLength={255}
                />
                <div className="col-sm-6 form-group">
                  <label>Ссылка в хедере</label>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        checked={isNameVisible}
                        onChange={e => this.setState({ isNameVisible: e.currentTarget.checked })}
                      />
                      &nbsp; Отображать лого с названием
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <TextField
              required
              invalid={useValidation && !nameGenitive}
              value={nameGenitive}
              onChange={value => this.setState({ nameGenitive: value })}
              containerClass="col-sm-6 mb-3"
              label="Название компании в родительном падеже"
              maxLength={255}
              placeholder="Например: Застройщика, Госстроя"
            />
            <DropdownSearchField
              value={builderId}
              values={builders.map(builder => ({ value: builder.id, label: builder.name }))}
              onChange={this.onBuilderChange}
              label="Застройщик"
              className="col-sm-6 form-group mb-3"
              hint="Важно! При изменении необходимо обновить информацию на стороне Adfox"
              required
            />
            <NumberField
              className="col-sm-6 form-group mb-3"
              value={jkId}
              onChange={id => this.setState({ jkId: id })}
              label="ИД ЖК"
              placeholder="id"
              invalid={useValidation && !jkId}
              minValue={0}
              required
              disableFractional
            />
            <NumberField
              className="col-sm-6 form-group mb-3"
              value={userId}
              onChange={id => this.setState({ userId: id })}
              label="ИД личного кабинета"
              placeholder="id"
              invalid={useValidation && !userId}
              minValue={0}
              required
              disableFractional
            />
            <MultiselectorField
              label="Регион"
              className="col-sm-6 form-group"
              value={regionList}
              values={regionDropdownValues}
              onChange={this.onRegionsChange}
              required
              noEmpty
            />
            <div className="col-md-4 col-sm-6 form-group mb-3">
              <label>
                Цвет акцентных элементов <span className="text-danger">*</span>
              </label>
              <ColorSelect
                invalid={useValidation && !colorHexIsValid(colorHex)}
                colorHex={colorHex}
                onChange={color => this.setState({ colorHex: color })}
              />
            </div>
            <SelectField
              className="col-md-4 form-group mb-3"
              onChange={(value: TSpecialPromoStatus) => this.setState({ status: value })}
              value={status}
              values={specialPromoStatusDropdownValues}
              label="Статус"
            />
            <TextField
              value={disclaimer || ''}
              onChange={value => this.setState({ disclaimer: value })}
              containerClass="col-sm-12 form-group mb-3"
              label="Дисклеймер для логотипа в шапке"
            />
            <TextField
              value={promoText || ''}
              onChange={value => this.setState({ promoText: value })}
              containerClass="col-sm-12 form-group mb-3"
              label="Текст для промо-баннера"
              maxLength={255}
            />
            <TextField
              required
              invalid={useValidation && !textShowOffers}
              value={textShowOffers}
              onChange={value => this.setState({ textShowOffers: value })}
              containerClass="col-sm-6 mb-3"
              label="Текст ссылки над картой"
              maxLength={255}
              placeholder="Например: Показать ЖК от Госстроя"
            />
            <div className="col-sm-6 form-group">
              {createImageLabel(IMAGE_LABEL_TYPE.logoSmall)}
              <ImageSelect
                invalid={useValidation && !imageIsValid(logoSmall)}
                value={logoSmall}
                onChange={logo => this.setState({ logoSmall: logo })}
                minWidth={30}
                minHeight={30}
                previewWidth={30}
                previewHeight={30}
                inlinePreview
              />
            </div>
            <div className="col-xs-12">
              <div className="row">
                <div className="col-sm-6 form-group">
                  {createImageLabel(IMAGE_LABEL_TYPE.logoHeader)}
                  <ImageSelect
                    invalid={useValidation && !imageIsValid(logoHeader)}
                    value={logoHeader}
                    onChange={logo => this.setState({ logoHeader: logo })}
                    minWidth={1}
                    minHeight={108}
                    previewWidth={500}
                    previewHeight={54}
                    inlinePreview
                  />
                </div>
                <div className="col-sm-6 form-group">
                  {createImageLabel(IMAGE_LABEL_TYPE.pinImage)}
                  <ImageSelect
                    value={pinImage}
                    onChange={image => this.setState({ pinImage: image.base64 ? image : {} })}
                    minWidth={40}
                    minHeight={40}
                    previewWidth={30}
                    previewHeight={30}
                    inlinePreview
                    invalid={useValidation && !imageIsValid(pinImage)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 form-group">
                  {createImageLabel(IMAGE_LABEL_TYPE.logoMobile)}
                  <ImageSelect
                    value={logoMobile}
                    onChange={image => this.setState({ logoMobile: image.base64 ? image : {} })}
                    minWidth={30}
                    minHeight={30}
                    previewWidth={30}
                    previewHeight={30}
                    inlinePreview
                  />
                </div>
                <div className="col-sm-6 form-group">
                  {createImageLabel(IMAGE_LABEL_TYPE.logoApp)}
                  <ImageSelect
                    value={logoApp}
                    onChange={image => this.setState({ logoApp: image.base64 ? image : {} })}
                    minWidth={40}
                    minHeight={40}
                    previewWidth={40}
                    previewHeight={40}
                    inlinePreview
                    invalid={useValidation && !imageIsValid(logoApp)}
                    accept="image/svg+xml"
                  />
                </div>
              </div>
            </div>
          </div>
        </FormPanel>
        <h3>Настройки лендинга</h3>
        <BannerPanel
          useValidation={useValidation}
          bannerList={bannerList}
          logoBig={logoBig}
          textColorHex={textColorHex}
          builderJks={builderJks}
          regions={regions}
          onBannerListChange={banners => this.setState({ bannerList: banners })}
          onLogoChange={logo => this.setState({ logoBig: logo })}
          onTextColorChange={color => this.setState({ textColorHex: color })}
        />
        <JksPanel
          jkList={jkList}
          builderJks={builderJks}
          regions={regions}
          onChange={value => this.setState({ jkList: value })}
        />
        <MapSettingsPanel
          useValidation={useValidation}
          mapSettingsJk={mapSettingsJk}
          onChange={value => this.setState({ mapSettingsJk: value })}
        />
        <OfficePanel
          useValidation={useValidation}
          officeList={officeList}
          mapSettingsOffice={mapSettingsOffice}
          onMapSettingsOfficeChange={value => this.setState({ mapSettingsOffice: value })}
          onOfficeListChange={value => this.setState({ officeList: value })}
        />
        <BuilderInfoPanel
          useValidation={useValidation}
          info={info}
          onChange={builderInfo => this.setState({ info: builderInfo })}
        />
        <h3>Таргетинг конкурентных ЖК</h3>
        <CompetitiveJkPanel
          regions={regions}
          competitiveJks={competitiveJks}
          competitiveJkList={competitiveJkList}
          builderJks={builderJks}
          onChange={value => this.setState({ competitiveJkList: value })}
        />
        <SpecialPromoHistory history={history} />
        <div className="clearfix">{this.renderActionButtons()}</div>
      </div>
    );
  }

  private renderActionButtons() {
    return (
      <ActionButtons
        className="float-end"
        onSubmit={this.onSubmit}
        onCancel={() => (window.location.href = '/special-promos')}
        isDisabled={this.props.saving}
      />
    );
  }

  private onSubmit = () => {
    if (!formIsValid(this.state)) {
      this.setState({ useValidation: true });

      return;
    }

    this.props.onSubmit(formatFormStateToSpecialPromo(this.state));
  };

  private onRegionsChange = async (regionList: number[]) => {
    if (this.regionChangeInProcess) {
      return;
    }
    this.regionChangeInProcess = true;

    try {
      const { httpApi } = this.props;
      if (!httpApi) {
        return;
      }

      const { builderId, competitiveJkList } = this.state;

      const competitiveJks = await getJksByRegions(httpApi, regionList, builderId);

      this.setState(
        {
          competitiveJkList: competitiveJkList.filter(competitiveJk => regionList.includes(competitiveJk.regionId)),
          competitiveJks,
          regionList,
        },
        () => (this.regionChangeInProcess = false),
      );
    } catch (e) {
      this.regionChangeInProcess = false;
    }
  };

  private onBuilderChange = async (builderId: number) => {
    if (this.builderChangeInProcess) {
      return;
    }
    this.builderChangeInProcess = true;

    try {
      const { httpApi } = this.props;
      if (!httpApi) {
        return;
      }

      const { regionList, competitiveJkList, bannerList } = this.state;

      this.setState({ builderId });

      const [builderJks, competitiveJks] = await Promise.all([
        getJksByBuilder(httpApi, builderId),
        getJksByRegions(httpApi, regionList, builderId),
      ]);

      this.setState(
        {
          bannerList: bannerList.map(banner => ({ ...banner, buildingId: undefined })),
          builderJks,
          competitiveJkList: competitiveJkList
            .filter(competitiveJk => competitiveJks.some(jk => jk.id === competitiveJk.id))
            .map(competitiveJk => ({ ...competitiveJk, substitutiveJkList: [] })),
          competitiveJks,
          jkList: [],
        },
        () => (this.builderChangeInProcess = false),
      );
    } catch (e) {
      this.builderChangeInProcess = false;
    }
  };
}
