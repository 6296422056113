import * as React from 'react';

import { IAdminUpdateSpecialPromoRequest } from '../../repositories/special-promos/v3/admin-update-special-promo';
import { IAdminGetSpecialPromoSchema } from '../../types/adminGetSpecialPromo';
import { IHttpApi } from '@cian/http-api/shared';

import { ISpecialPromoLog } from '../../common/interfaces';
import { IBuilder } from '../../http/get-builder-list';
import { IJkForList } from '../../http/get-jks-by-builder';
import { IRegion } from '../../http/get-regions';
import { updateSpecialPromo } from '../../http/update-special-promo';
import { Alert } from '../alert';
import { SpecialPromoForm } from '../special-promo-form';

interface IProps {
  httpApi?: IHttpApi;
  builders: IBuilder[];
  regions: IRegion[];
  builderJks: IJkForList[];
  competitiveJks: IJkForList[];
  specialPromo: IAdminGetSpecialPromoSchema;
  history?: ISpecialPromoLog[];
  success: boolean;
}

interface IState {
  showSuccessModal: boolean;
  showDangerModal: boolean;
  saving: boolean;
}

export class EditSpecialPromo extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      saving: false,
      showDangerModal: false,
      showSuccessModal: props.success || false,
    };
  }

  public componentDidMount() {
    const {
      success,
      specialPromo: { id },
    } = this.props;

    if (success) {
      this.setHideModalTimeout();
      window.history.replaceState({}, '', `/special-promos/${id}/`);
    }
  }

  public render() {
    const { builders, regions, builderJks, httpApi, specialPromo, history, competitiveJks } = this.props;

    return (
      <div className="container-md pt-4">
        {this.state.showSuccessModal && (
          <Alert
            onClick={() => this.setState({ showSuccessModal: false })}
            text="Данные успешно сохранены"
            type="success"
          />
        )}
        {this.state.showDangerModal && (
          <Alert
            onClick={() => this.setState({ showDangerModal: false })}
            text="Не удалось сохранить данные"
            type="danger"
          />
        )}

        <SpecialPromoForm
          builders={builders}
          saving={false}
          title="Редактирование шаблона спецпроекта"
          regions={regions}
          httpApi={httpApi}
          builderJks={builderJks}
          competitiveJks={competitiveJks}
          specialPromo={specialPromo}
          onSubmit={this.handleSubmitForm}
          history={history}
        />
      </div>
    );
  }

  private handleSubmitForm = async (specialPromo: IAdminUpdateSpecialPromoRequest) => {
    const { httpApi } = this.props;
    if (!httpApi) {
      return;
    }

    this.setState({ saving: true });

    try {
      await updateSpecialPromo(httpApi, specialPromo as IAdminUpdateSpecialPromoRequest);
      this.setState({ showSuccessModal: true, saving: false });

      window.location.href = `/special-promos/${specialPromo.id}/?success=true`;
    } catch (err) {
      this.setState({ showDangerModal: true, saving: false });
      this.setHideModalTimeout();
    }
  };

  private setHideModalTimeout() {
    window.setTimeout(() => this.setState({ showSuccessModal: false, showDangerModal: false }), 3000);
  }
}
