import { IStyleArgument, mergeStyles } from '@cian/utils/lib/shared/style';
import * as React from 'react';
import { IMultilineTextFieldStyles } from './multiline_text_field.css';

const styles: IMultilineTextFieldStyles = require('./multiline_text_field.css');

export interface IMultilineTextFieldDOMAttributes {
  disabled?: boolean;
  name?: string;

  onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>;
  onKeyPress?: React.KeyboardEventHandler<HTMLTextAreaElement>;
  onKeyUp?: React.KeyboardEventHandler<HTMLTextAreaElement>;

  onFocus?: React.FocusEventHandler<HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;

  placeholder?: string;
  required?: boolean;
}

export interface IMultilineTextFieldProps extends IMultilineTextFieldDOMAttributes {
  invalid?: boolean;
  maxLength?: number;
  minRows?: number;
  onValueChange(value: string): void;
  value: string;
  containerStyle?: IStyleArgument;
  inputStyle?: IStyleArgument;
  lengthStyle?: IStyleArgument;
  inFocus?: boolean;
}

export class MultilineTextField extends React.Component<IMultilineTextFieldProps, {}> {
  public static defaultProps = {
    minRows: 1,
  };

  private textArea: HTMLTextAreaElement | null;

  public componentDidMount() {
    // Trigger scroll check
    this.forceUpdate();
    this.setFocusIfNeed();
  }

  public componentDidUpdate(prevProps: IMultilineTextFieldProps) {
    if (this.props.value !== prevProps.value) {
      // Trigger scroll check
      this.forceUpdate();
    }
  }

  public render() {
    const {
      disabled,
      containerStyle,
      inputStyle,
      invalid,
      lengthStyle,
      maxLength,
      name,
      onBlur,
      onFocus,
      onKeyDown,
      onKeyPress,
      onKeyUp,
      placeholder,
      required,
      value,
    } = this.props;

    return (
      <div {...mergeStyles(styles['container'], (required as IStyleArgument) && styles['required'], containerStyle)}>
        <textarea
          ref={textArea => (this.textArea = textArea)}
          {...mergeStyles(styles['input'], (invalid as IStyleArgument) && styles['invalid'], inputStyle)}
          rows={this.calculateRowsCount()}
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          onChange={this.onChange}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyPress={onKeyPress}
          onKeyUp={onKeyUp}
          name={name}
        />
        {this.isTextLengthVisible() && (
          <span
            {...mergeStyles(
              styles['text_length'],
              (this.props.disabled as IStyleArgument) && styles['text_length-disabled'],
              (this.hasScroll() && styles['text_length-scroll']) || undefined,
              (maxLength && this.calculateTextLength(maxLength) < 0 && styles['invalid']) || undefined,
              lengthStyle,
            )}
          >
            {maxLength && this.calculateTextLength(maxLength)}
          </span>
        )}
      </div>
    );
  }

  public hasScroll() {
    if (!this.textArea) {
      return false;
    }

    return this.textArea.scrollHeight > this.textArea.clientHeight;
  }

  private onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.props.onValueChange(event.currentTarget.value);
  };

  private calculateRowsCount() {
    const rows = this.props.value.split('\n').length;

    return this.props.minRows && rows < this.props.minRows ? this.props.minRows : rows;
  }

  private calculateTextLength(maxLength: number) {
    return maxLength - this.props.value.length;
  }

  private isTextLengthVisible() {
    return typeof this.props.maxLength === 'number';
  }

  private setFocusIfNeed() {
    if (this.props.inFocus && this.textArea) {
      const text = this.textArea.value;

      this.textArea.focus();
      this.textArea.setSelectionRange(text.length, text.length);
    }
  }
}
