import { IHttpApi } from '@cian/http-api/shared';
import { fetchAdminCheckPermissions } from '../repositories/special-promos/v1/admin-check-permissions';

export async function checkPermissions({ httpApi }: { httpApi: IHttpApi }) {
  const { response, statusCode } = await fetchAdminCheckPermissions({ httpApi, parameters: {} });

  if (statusCode !== 200 || (response && 'errors' in response)) {
    throw response;
  }

  return response;
}
