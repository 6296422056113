import * as React from 'react';
import * as styles from './index.css';

import { NumberInput } from '@cian/ui-kit';
import { mergeStyles } from '@cian/utils/lib/shared/style';

interface INumberFieldProps {
  onChange(value?: number): void;
  value: number | void;
  className?: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disableFractional?: boolean;
  fractionalLimit?: number;
  minValue?: number;
  maxValue?: number;
  invalid?: boolean;
  hint?: string;
}

export const NumberField = (props: INumberFieldProps) => (
  <div {...mergeStyles(props.className)}>
    {props.label && (
      <label className="form-label">
        {props.label}&nbsp;
        {props.required && <span className="text-danger">*</span>}
      </label>
    )}

    <NumberInput
      placeholder={props.placeholder}
      invalid={props.invalid}
      min={props.minValue}
      max={props.maxValue}
      decimalDigits={props.disableFractional ? 0 : props.fractionalLimit}
      onChange={props.onChange}
      value={props.value && typeof props.value === 'number' ? props.value : undefined}
    />

    {props.hint && <div className={styles['hint']}>{props.hint}</div>}
  </div>
);
