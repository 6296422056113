import * as React from 'react';

import { mergeStyles } from '@cian/utils/lib/shared/style';

import { ISubstitutiveJk } from '../../../../common/interfaces';
import { IJkForList } from '../../../../http/get-jks-by-builder';
import { IRegion } from '../../../../http/get-regions';
import { SubstitutiveJkList } from '../../substitutive-jk-list';

import * as styles from './index.css';

interface ICompetitiveJkTabProps {
  regions: IRegion[];
  label: string;
  builderJks: IJkForList[];
  substitutiveJkList: ISubstitutiveJk[];
  onChange(value: ISubstitutiveJk[]): void;
  onDelete(): void;
}

interface ICompetitiveJkTabState {
  active: boolean;
}

export class CompetitiveJkTab extends React.Component<ICompetitiveJkTabProps, ICompetitiveJkTabState> {
  public state = {
    active: true,
  };

  public render() {
    const { active } = this.state;
    const { onDelete, regions, substitutiveJkList, builderJks, onChange, label } = this.props;

    return (
      <div className="card panel-default">
        <div {...mergeStyles('card-heading  p-2', styles['head'])}>
          <h5 className="card-title">
            <a
              href="#"
              className="collapsed text-decoration-none"
              onClick={e => {
                e.preventDefault();
                this.setState({ active: !active });
              }}
            >
              {label} <i className={`bi-chevron-${active ? 'up' : 'down'}`} />
            </a>
          </h5>
          <button className="btn btn-xs btn-danger" onClick={onDelete}>
            Удалить
          </button>
        </div>
        <div {...mergeStyles('panel-collapse collapse', active && 'show')}>
          <div className="card-body">
            <SubstitutiveJkList
              regions={regions}
              substitutiveJkList={substitutiveJkList}
              builderJks={builderJks}
              onChange={onChange}
            />
          </div>
        </div>
      </div>
    );
  }
}
