import { IHttpApi } from '@cian/http-api/shared';

export interface IRegion {
  id: number;
  displayName: string;
}

interface IGetRegionsResponse {
  data: {
    items: IRegion[];
  };
}

export async function getRegions(httpApi: IHttpApi) {
  const { response, statusCode } = await httpApi.fetch({
    apiType: 'private',
    assertStatusCodes: [200, 400],
    method: 'GET',
    microserviceName: 'special-promos',
    pathApi: '/v1/get-regions/',
  });

  if (statusCode !== 200 || (response && 'errors' in response)) {
    throw response;
  }

  return (response as IGetRegionsResponse).data.items;
}

export const MOSCOW_REGION_ID = 1;
export const SPB_REGION_ID = 2;
export const MO_REGION_ID = 4593;
export const LO_REGION_ID = 4588;
export const EKB_REGION_ID = 4612;

export const EXCLUDED_REGIONS = [MOSCOW_REGION_ID, MO_REGION_ID];

export const DEFAULT_SELECTED_REGIONS = [EKB_REGION_ID];
