/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TAdminUpdateSpecialPromoModel,
  IAdminUpdateSpecialPromoRequest,
  IMappers,
  IAdminUpdateSpecialPromoResponse,
  IAdminUpdateSpecialPromoResponseError,
  TAdminUpdateSpecialPromoResponse,
} from './types';

const defaultConfig: TAdminUpdateSpecialPromoModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'special-promos',
  pathApi: '/v3/admin-update-special-promo/',
  hostType: 'api',
} as TAdminUpdateSpecialPromoModel;

function createAdminUpdateSpecialPromoModel(
  parameters: IAdminUpdateSpecialPromoRequest,
): TAdminUpdateSpecialPromoModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IAdminUpdateSpecialPromoOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IAdminUpdateSpecialPromoRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchAdminUpdateSpecialPromo<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IAdminUpdateSpecialPromoOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TAdminUpdateSpecialPromoResponse
> {
  const { response, statusCode } = await httpApi.fetch(createAdminUpdateSpecialPromoModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IAdminUpdateSpecialPromoResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IAdminUpdateSpecialPromoResponseError);
    }
  }

  return { response, statusCode } as TAdminUpdateSpecialPromoResponse;
}

export { defaultConfig, createAdminUpdateSpecialPromoModel, fetchAdminUpdateSpecialPromo };
