import { IHttpApi } from '@cian/http-api/shared';
import {
  fetchAdminCreateSpecialPromo,
  IAdminCreateSpecialPromoRequest,
} from '../repositories/special-promos/v3/admin-create-special-promo';

export async function createSpecialPromo(httpApi: IHttpApi, specialPromo: IAdminCreateSpecialPromoRequest) {
  const { response, statusCode } = await fetchAdminCreateSpecialPromo({
    httpApi,
    parameters: specialPromo,
    config: {
      requestConfig: {
        headers: [['Content-Type', 'application/x-www-form-urlencoded']],
      },
    },
  });

  if (statusCode !== 200 || 'errors' in response) {
    throw response;
  }

  return response;
}
