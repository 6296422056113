/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TAdminDeleteSpecialPromoModel,
  IAdminDeleteSpecialPromoRequest,
  IMappers,
  IAdminDeleteSpecialPromoResponse,
  IAdminDeleteSpecialPromoResponseError,
  TAdminDeleteSpecialPromoResponse,
} from './types';

const defaultConfig: TAdminDeleteSpecialPromoModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'special-promos',
  pathApi: '/v1/admin-delete-special-promo/',
  hostType: 'api',
} as TAdminDeleteSpecialPromoModel;

function createAdminDeleteSpecialPromoModel(
  parameters: IAdminDeleteSpecialPromoRequest,
): TAdminDeleteSpecialPromoModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IAdminDeleteSpecialPromoOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IAdminDeleteSpecialPromoRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchAdminDeleteSpecialPromo<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IAdminDeleteSpecialPromoOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TAdminDeleteSpecialPromoResponse
> {
  const { response, statusCode } = await httpApi.fetch(createAdminDeleteSpecialPromoModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IAdminDeleteSpecialPromoResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IAdminDeleteSpecialPromoResponseError);
    }
  }

  return { response, statusCode } as TAdminDeleteSpecialPromoResponse;
}

export { defaultConfig, createAdminDeleteSpecialPromoModel, fetchAdminDeleteSpecialPromo };
