import * as React from 'react';

import { getQueryStringByFilter } from '../../common/helpers';
import { IFilter } from '../../common/interfaces';
import { SPECIAL_PROMOS_ROUTE } from '../../routes';

interface IPaginationProps {
  filter: IFilter;
  onPageChange(value: number): void;
  pageCount: number;
}

const getPagesList = (pageCount: number, activePage: number): number[] => {
  const maxPage = activePage + 7;
  const minPage = activePage - 7;
  const max = maxPage > pageCount ? pageCount : maxPage;
  const min = minPage > 0 ? minPage : 1;

  return Array.from(Array(max - min + 1), (_, index) => index + min);
};

export const Pagination = (props: IPaginationProps) => {
  const { filter, pageCount, onPageChange } = props;

  if (pageCount <= 1) {
    return null;
  }

  const activePage = filter.p || 1;
  const pagesList = getPagesList(pageCount, activePage);

  return (
    <div>
      <ul className="pagination">
        {activePage - 8 > 0 && (
          <li className="page-item">
            <a className="page-link" href="#" aria-label="Previous">
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>
        )}
        {pagesList.map(page => (
          <li key={page} className={`page-item ${activePage === page ? 'active' : ''}`}>
            <a
              className="page-link"
              href={`${SPECIAL_PROMOS_ROUTE}?${getQueryStringByFilter({ ...filter, p: page })}`}
              onClick={event => {
                event.preventDefault();
                onPageChange(page);
              }}
            >
              {page}
            </a>
          </li>
        ))}
        {activePage + 8 <= pageCount && (
          <li className="page-item">
            <a className="page-link" href="#" aria-label="Next">
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        )}
      </ul>
    </div>
  );
};
