import * as React from 'react';

export interface IDropdownValue {
  value: number | string;
  label: string;
}

interface ISelectFieldProps {
  onChange(value: string | void): void;
  value: string | number;
  values: IDropdownValue[];
  className?: string;
  label?: string;
  htmlId?: string;
  required?: boolean;
  disabled?: boolean;
  canEmpty?: boolean;
  emptyText?: string;
}

export const SelectField = (props: ISelectFieldProps) => (
  <div className={props.className}>
    {props.label && (
      <label className="form-label" htmlFor={props.htmlId}>
        {props.label}&nbsp;
        {props.required && <span className="text-danger">*</span>}
      </label>
    )}
    <select
      disabled={props.disabled}
      className="form-select"
      id={props.htmlId}
      value={props.value}
      onChange={e => props.onChange(e.currentTarget.value || undefined)}
    >
      {props.canEmpty && <option value="">{props.emptyText || 'не выбрано'}</option>}
      {props.values.map((selectValue, index) => (
        <option value={selectValue.value} key={index}>
          {selectValue.label}
        </option>
      ))}
    </select>
  </div>
);
