import * as React from 'react';

import { MultilineTextField } from '../../multiline-text-field/multiline_text_field';
import { mergeStyles } from '@cian/utils';

import { IBuilderInfo } from '../../../common/interfaces';
import { TextField } from '../../text-field';
import { FormPanel } from '../form-panel';
import { builderInfoIsEmpty } from '../helpers';

import * as styles from './index.css';

interface IProps {
  info: IBuilderInfo;
  onChange(value: IBuilderInfo): void;
  useValidation: boolean;
}

export const BuilderInfoPanel = (props: IProps) => {
  const { info, onChange, useValidation } = props;

  if (builderInfoIsEmpty(info)) {
    return (
      <div className="form-group mb-3">
        <button className="btn btn-success" onClick={() => onChange({ title: '', html: '', text: '' })}>
          <i className="bi-plus" /> Добавить блок с информацией о застройщике
        </button>
      </div>
    );
  }

  const removePanelButton = (
    <button className="btn btn-xs btn-danger float-end" onClick={() => onChange({})}>
      Удалить блок
    </button>
  );

  return (
    <FormPanel title="Блок с информацией о застройщике (необязательно)" headButton={removePanelButton}>
      <div className="row">
        <TextField
          invalid={useValidation && !info.title}
          value={info.title || ''}
          onChange={title => onChange({ ...info, title })}
          maxLength={255}
          label="Заголовок"
          placeholder="например: Застройщик"
          containerClass="col-md-8 mb-3"
          required
        />
      </div>
      <div className="row">
        <div className="form-group col-md-8 mb-3">
          <label>Текстовое описание, используется в мобильных приложениях (не более 190 символов)</label>
          <MultilineTextField
            minRows={5}
            maxLength={190}
            value={info.text || ''}
            onValueChange={text =>
              onChange({
                ...info,
                text: text.length > 190 ? text.slice(0, 190) : text,
              })
            }
            inputStyle={mergeStyles('form-control', styles['textarea'], styles['description-mobile'])}
          />
        </div>
      </div>
      <div className="form-group mb-3">
        <label>
          HTML, CSS, JS код <span className="text-danger">*</span>
        </label>
        <MultilineTextField
          minRows={10}
          value={info.html || ''}
          onValueChange={html => onChange({ ...info, html })}
          inputStyle={mergeStyles('form-control', styles['textarea'], useValidation && !info.html && styles['invalid'])}
        />
      </div>
    </FormPanel>
  );
};
