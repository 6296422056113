import { mergeStyles } from '@cian/utils/lib/shared/style';
import * as React from 'react';
import * as styles from './index.css';

export interface IImage {
  url?: string;
  base64?: string;
}

interface IImageSelectProps {
  onChange(value: IImage): void;
  loadBtnText?: string;
  value: IImage;
  className?: string;
  minWidth: number;
  minHeight: number;
  previewWidth?: number | string;
  previewHeight?: number | string;
  accept?: string;
  inlinePreview?: boolean;
  invalid?: boolean;
}

interface IImageSelectState {
  currentUrl?: string;
  savedUrl?: string;
  invalidMinSize: boolean;
  invalidFileType: boolean;
}

export class ImageSelect extends React.Component<IImageSelectProps, IImageSelectState> {
  public constructor(props: IImageSelectProps) {
    super(props);

    this.state = {
      currentUrl: props.value.url,
      invalidFileType: false,
      invalidMinSize: false,
      savedUrl: props.value.url,
    };
  }

  public _UNSAFE_componentWillReceiveProps(nextProps: IImageSelectProps) {
    const { value } = nextProps;

    this.setState({ currentUrl: value.base64 || value.url });
  }

  public render() {
    const { className, loadBtnText, minWidth, minHeight, previewHeight, previewWidth, accept, inlinePreview, invalid } =
      this.props;
    const { currentUrl, invalidMinSize, invalidFileType } = this.state;

    return (
      <div className={className}>
        {currentUrl && (
          <button
            className="btn btn-danger btn-sm"
            style={{ float: inlinePreview ? 'left' : 'none' }}
            onClick={this.removeImage}
          >
            Удалить
          </button>
        )}
        {!currentUrl && (
          <label {...mergeStyles(styles['label'], styles['label-success'])}>
            <input
              type="file"
              accept={accept || 'image/*'}
              className={styles['input']}
              onChange={this.fileChangeHandler}
            />
            {loadBtnText || 'Выбрать'}
          </label>
        )}
        {currentUrl && (
          <div className={inlinePreview ? styles['preview-inline'] : styles['preview']}>
            <img style={{ maxWidth: previewWidth || 200, height: previewHeight || 200 }} src={currentUrl} />
          </div>
        )}
        {invalidMinSize && (
          <div className="text-danger">Мин. размеры изображения {`${minWidth}х${minHeight}`}&nbsp;px</div>
        )}
        {invalidFileType && <div className="text-danger">Неверный формат файла, разрешены {accept}</div>}
        {invalid && <div className="text-danger">Выберите изображение</div>}
      </div>
    );
  }

  private removeImage = () => {
    this.props.onChange({});

    this.setState({ currentUrl: undefined });
  };

  private fileChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      invalidFileType: false,
      invalidMinSize: false,
    });
    const file = e.currentTarget.files && e.currentTarget.files[0];
    const reader = new FileReader();

    if (file) {
      reader.onloadend = () => {
        const base64 = reader.result as string;
        const imageElement = new Image();

        imageElement.onload = () => {
          const { minWidth, minHeight, accept } = this.props;

          if (accept && file.type !== accept) {
            this.setState({ invalidFileType: true });

            return;
          }

          if (file.type === 'image/svg+xml' || (imageElement.width >= minWidth && imageElement.height >= minHeight)) {
            this.props.onChange({
              base64,
              url: this.state.savedUrl,
            });

            this.setState({ currentUrl: base64 });
          } else {
            this.setState({ invalidMinSize: true });
          }
        };

        imageElement.src = base64;
      };

      reader.readAsDataURL(file);
    }
  };
}
