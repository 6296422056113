import { ErrorLogComponent } from '@cian/error-log-component';
import { UiKitContextProvider } from '@cian/ui-kit/context';
import { uaToDeviceType } from '@cian/ui-kit/utils';
import * as React from 'react';
import { Provider } from 'react-redux';
import { EditSpecialPromo } from '../../components/edit-special-promo';

import { TReduxStore } from '../../types/redux';
import { ApplicationContext } from '../../utils/applicationContext';
import { IApplicationSpecialPromosEditContext } from '../../types/applicationContext';

export interface IApplicationProps {
  context: IApplicationSpecialPromosEditContext;
  reduxStore: TReduxStore;
}

export const SpecialPromosEditContainer: React.FC<IApplicationProps> = ({ reduxStore, context }) => {
  const {
    httpApi,
    logger,
    custom: { builders, regions, builderJks, competitiveJks, userAgent, specialPromo, history, success },
  } = context;

  return (
    <ErrorLogComponent logger={logger}>
      <ApplicationContext.Provider value={context}>
        <UiKitContextProvider deviceType={uaToDeviceType(userAgent || undefined)}>
          <Provider store={reduxStore}>
            <EditSpecialPromo
              httpApi={httpApi}
              competitiveJks={competitiveJks}
              builders={builders}
              builderJks={builderJks}
              regions={regions}
              specialPromo={specialPromo}
              history={history}
              success={success}
            />
          </Provider>
        </UiKitContextProvider>
      </ApplicationContext.Provider>
    </ErrorLogComponent>
  );
};
