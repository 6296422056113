import * as React from 'react';
import { arrayMove, SortEnd } from 'react-sortable-hoc';

import { IJkForList } from '../../../http/get-jks-by-builder';
import { IRegion } from '../../../http/get-regions';
import { DropdownSearchField } from '../../dropdown-search';
import { FormPanel } from '../form-panel';
import { createJkLabelText } from '../helpers';
import { SortableJkList } from '../sortable-list';

interface IJksPanelProps {
  jkList: number[];
  builderJks: IJkForList[];
  regions: IRegion[];
  onChange(jks: number[]): void;
}

interface IJksPanelState {
  selectedJkId?: number;
  filteredJks: IJkForList[];
}

const getFilteredJksSelectedJkId = (
  builderJks: IJkForList[],
  jkList: number[],
): {
  selectedJkId?: number;
  filteredJks: IJkForList[];
} => {
  const filteredJks = builderJks.filter(jk => !jkList.includes(jk.id));

  return {
    filteredJks,
    selectedJkId: filteredJks[0] && filteredJks[0].id,
  };
};

const getSortedJks = (builderJks: IJkForList[], jkList: number[]) =>
  jkList.map(jkId => builderJks.find(jk => jk.id === jkId)).filter(jk => jk !== undefined) as IJkForList[];

const getUnfilteredJkIds = (builderJks: IJkForList[], jkList: number[]) =>
  jkList.filter(jkId => !builderJks.some(jk => jk.id === jkId));

export class JksPanel extends React.Component<IJksPanelProps, IJksPanelState> {
  public constructor(props: IJksPanelProps) {
    super(props);

    this.state = {
      ...getFilteredJksSelectedJkId(props.builderJks, props.jkList),
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IJksPanelProps) {
    if (nextProps.builderJks !== this.props.builderJks) {
      this.setState(getFilteredJksSelectedJkId(nextProps.builderJks, nextProps.jkList));
    }
  }

  public render() {
    const { selectedJkId, filteredJks } = this.state;
    const { jkList, builderJks, regions } = this.props;
    const dropdownValues = filteredJks.map(jk => ({
      label: createJkLabelText(jk, regions),
      value: jk.id,
    }));

    return (
      <FormPanel title="Блок с ЖК (порядок отображение ЖК в списке)">
        <div className="row">
          <DropdownSearchField
            value={selectedJkId || 0}
            values={dropdownValues}
            onChange={(value: number) => this.setState({ selectedJkId: value })}
            className="col-md-6 col-sm-8 form-group mb-3"
          />
          <div className="col-md-6 col-sm-4 form-group mb-3">
            <button
              data-testid="add-button"
              className="btn btn-success"
              onClick={this.handleAddJk}
              disabled={!filteredJks.length}
            >
              Добавить
            </button>
          </div>
        </div>
        {!!jkList.length && (
          <SortableJkList
            regions={regions}
            jks={getSortedJks(builderJks, jkList)}
            onSortEnd={this.handleSortEnd}
            onDelete={this.handleDeleteJk}
          />
        )}
      </FormPanel>
    );
  }

  private handleSortEnd = (result: SortEnd) => {
    const { oldIndex, newIndex } = result;
    const { jkList, onChange, builderJks } = this.props;
    const oldJkList = getSortedJks(builderJks, jkList).map(el => el.id);

    const newJkList = [...arrayMove(oldJkList, oldIndex, newIndex), ...getUnfilteredJkIds(builderJks, jkList)];

    onChange(newJkList);
  };

  private handleDeleteJk = (jkId: number) => {
    const { onChange, jkList, builderJks } = this.props;
    const newJkList = jkList.filter(value => value !== jkId);

    onChange(newJkList);
    this.setState(getFilteredJksSelectedJkId(builderJks, newJkList));
  };

  private handleAddJk = () => {
    const { selectedJkId } = this.state;

    if (selectedJkId !== undefined) {
      const { onChange, jkList, builderJks } = this.props;

      const newJkList = [...jkList, selectedJkId];

      onChange(newJkList);
      this.setState(getFilteredJksSelectedJkId(builderJks, newJkList));
    }
  };
}
