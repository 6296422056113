import * as React from 'react';

import { mergeStyles } from '@cian/utils/lib/shared/style';

import * as styles from './index.css';

interface IAlertProps {
  onClick?(): void;
  text: string;
  type: 'success' | 'danger';
}

export const Alert = (props: IAlertProps) => (
  <div className={styles['container']} onClick={props.onClick}>
    <div {...mergeStyles(styles['alert'], styles[props.type])}>{props.text}</div>
  </div>
);
