import { ErrorLogComponent } from '@cian/error-log-component';
import { UiKitContextProvider } from '@cian/ui-kit/context';
import { uaToDeviceType } from '@cian/ui-kit/utils';
import * as React from 'react';
import { Provider } from 'react-redux';
import { SpecialPromos } from '../../components/special-promos';

import { TReduxStore } from '../../types/redux';
import { ApplicationContext } from '../../utils/applicationContext';
import { IApplicationSpecialPromosContext } from '../../types/applicationContext';

export interface IApplicationProps {
  context: IApplicationSpecialPromosContext;
  reduxStore: TReduxStore;
}

export const SpecialPromosContainer: React.FC<IApplicationProps> = ({ reduxStore, context }) => {
  const {
    logger,
    httpApi,
    custom: { userAgent, filter, spList, regions, totalPages },
  } = context;

  return (
    <ErrorLogComponent logger={logger}>
      <ApplicationContext.Provider value={context}>
        <UiKitContextProvider deviceType={uaToDeviceType(userAgent || undefined)}>
          <Provider store={reduxStore}>
            <SpecialPromos
              httpApi={httpApi}
              spList={spList}
              totalPages={totalPages}
              filter={filter}
              regions={regions}
            />
          </Provider>
        </UiKitContextProvider>
      </ApplicationContext.Provider>
    </ErrorLogComponent>
  );
};
