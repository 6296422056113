import * as React from 'react';

import { IValidationInfo, logOn } from '@cian/auth';
import { IHttpApi } from '@cian/http-api/shared';

import { SPECIAL_PROMOS_ROUTE } from '../../routes';
import { checkPermissions } from '../../http/check-permissions';

interface IAuthProps {
  httpApi: IHttpApi;
  authUrl: string;
}

interface IAuthState {
  email: string;
  password: string;
  persistent: boolean;
  errorPermissions: boolean;
  errorRequest: boolean;
  errorValidationMessage: string;
}

export class Auth extends React.Component<IAuthProps, IAuthState> {
  public constructor(props: IAuthProps) {
    super(props);

    this.state = {
      email: '',
      errorPermissions: false,
      errorRequest: false,
      errorValidationMessage: '',
      password: '',
      persistent: false,
    };
  }

  public render() {
    return (
      <div className="container-md mt-4">
        <div className="card">
          <div className="card-header">
            <h1>Вход на сайт</h1>
          </div>
          <div className="card-body">
            <form className="form-horizontal" onSubmit={this.login.bind(this)}>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  value={this.state.email}
                  onChange={this.emailChange}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Пароль <span className="text-danger">*</span>
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={this.state.password}
                  onChange={this.passwordChange}
                />
              </div>

              <div className="mb-3 form-check">
                <input
                  id="remember"
                  className="form-check-input"
                  type="checkbox"
                  checked={this.state.persistent}
                  onChange={this.persistentChange}
                />
                <label htmlFor="remember" className="form-check-label">
                  запомнить меня
                </label>
              </div>

              <button type="submit" className="btn btn-primary">
                Войти
              </button>
            </form>
          </div>
        </div>
        {this.state.errorPermissions && (
          <div className="text-danger">Недостаточно прав для просмотр данного раздела</div>
        )}
        {this.state.errorValidationMessage && <div className="text-danger">{this.state.errorValidationMessage}</div>}
        {this.state.errorRequest && (
          <div className="text-danger">Произошла ошибка во время запроса, попробуйте еще.</div>
        )}
      </div>
    );
  }

  private getInitialValidationState = () => ({
    errorPermissions: false,
    errorRequest: false,
    errorValidationMessage: '',
  });

  private persistentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(Object.assign(this.getInitialValidationState(), { persistent: e.currentTarget.checked }));
  };

  private passwordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(Object.assign(this.getInitialValidationState(), { password: e.currentTarget.value }));
  };

  private emailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(Object.assign(this.getInitialValidationState(), { email: e.currentTarget.value }));
  };

  private async login(e: React.ChangeEvent<HTMLFormElement>) {
    const { httpApi, authUrl } = this.props;
    if (!httpApi) {
      return;
    }

    e.preventDefault();
    this.setState({
      errorPermissions: false,
      errorRequest: false,
      errorValidationMessage: '',
    });

    logOn(this.state.email, this.state.password, this.state.persistent, authUrl)
      .then(() => {
        checkPermissions({ httpApi })
          .then(({ accessAllowed }) => {
            if (accessAllowed) {
              window.location.href = SPECIAL_PROMOS_ROUTE;
            } else {
              this.setState({ errorPermissions: true });
            }
          })
          .catch(() => this.setState({ errorRequest: true }));
      })
      .catch((response: IValidationInfo) => {
        if (response.errors) {
          this.setState({ errorValidationMessage: response.message || response.errors[0].message });
        } else {
          this.setState({ errorRequest: true });
        }
      });
  }
}
