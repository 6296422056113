import * as React from 'react';

import { mergeStyles } from '@cian/utils/lib/shared/style';

import { bannerIsValid, imageIsValid } from '../../../common/helpers';
import { IBanner } from '../../../common/interfaces';
import { IJkForList } from '../../../http/get-jks-by-builder';
import { IRegion } from '../../../http/get-regions';
import { DropdownSearchField } from '../../dropdown-search';
import { ImageSelect } from '../../image-select';
import { TextField } from '../../text-field';
import { createJkLabelText } from '../helpers';
import { createImageLabel, IMAGE_LABEL_TYPE } from '../image_tooltips/index';

import * as styles from './index.css';

interface IProps {
  builderJks: IJkForList[];
  regions: IRegion[];
  banner: IBanner;
  active: boolean;
  indexNumber: number;
  showDeleteButton: boolean;
  useValidation: boolean;
  onToggle(): void;
  onDelete(): void;
  onChange(banner: IBanner): void;
}

export const BannerTab = (props: IProps) => {
  const {
    banner,
    active,
    indexNumber,
    onChange,
    onToggle,
    onDelete,
    showDeleteButton,
    useValidation,
    builderJks,
    regions,
  } = props;
  const bannerIsInvalid = useValidation && !bannerIsValid(banner);
  const jkDropdownValues = builderJks.map(jk => ({
    label: createJkLabelText(jk, regions),
    value: jk.id,
  }));

  return (
    <div {...mergeStyles(['card', 'panel-default mb-2'], styles['container'], bannerIsInvalid && styles['invalid'])}>
      <div {...mergeStyles('card-heading p-2', styles['head'])}>
        <h5 className="card-title m-0">
          <a
            className="collapsed text-decoration-none"
            onClick={e => {
              e.preventDefault();
              onToggle();
            }}
          >
            {`Баннер №${indexNumber}`} <i className={`bi-chevron-${active ? 'up' : 'down'}`} />
          </a>
        </h5>
        {showDeleteButton && (
          <button className="btn btn-xs btn-danger" onClick={onDelete}>
            Удалить
          </button>
        )}
      </div>
      <div {...mergeStyles('card-body collapse', active ? 'show' : '')}>
        <div className="panel-body">
          <div className="row">
            <div className="col-md-6">
              <TextField
                invalid={useValidation && !banner.text}
                value={banner.text}
                label="Текст баннера"
                maxLength={60}
                onChange={text => onChange({ ...banner, text })}
                required
              />
              <TextField
                invalid={useValidation && !banner.textButton}
                value={banner.textButton}
                label="Текст кнопки баннера"
                maxLength={255}
                onChange={textButton => onChange({ ...banner, textButton })}
                required
              />
              <TextField
                invalid={useValidation && !banner.urlLink}
                value={banner.urlLink}
                label="Ссылка для кнопки на баннере"
                maxLength={255}
                placeholder="http://www.example.com"
                onChange={urlLink => onChange({ ...banner, urlLink })}
                required
              />
              <TextField
                value={banner.disclaimer}
                label="Дисклеймер"
                onChange={disclaimer => onChange({ ...banner, disclaimer })}
              />
            </div>
            <div className="col-md-6">
              <DropdownSearchField
                value={banner.buildingId || 0}
                values={jkDropdownValues}
                emptyValue={0}
                onChange={(buildingId: number) =>
                  onChange({
                    ...banner,
                    buildingId: buildingId === 0 ? undefined : buildingId,
                  })
                }
                label="Связанный с баннером ЖК"
                className="form-group"
              />
              <div className="form-group">
                {createImageLabel(IMAGE_LABEL_TYPE.banner)}
                <ImageSelect
                  invalid={useValidation && !imageIsValid(banner.image)}
                  value={banner.image}
                  onChange={image => onChange({ ...banner, image })}
                  minWidth={1440}
                  minHeight={810}
                  previewWidth="100%"
                  previewHeight="none"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
