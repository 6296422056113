import * as React from 'react';

interface IProps {
  title: string;
  children?: React.ReactNode;
  headButton?: React.ReactNode;
}

export const FormPanel = (props: IProps) => (
  <div className="card mb-4">
    <div className="card-header bg-primary text-white">
      {props.title}
      {props.headButton}
    </div>
    <div className="card-body">{props.children}</div>
  </div>
);
