import * as React from 'react';

import { mergeStyles } from '@cian/utils';

import { ICompetitiveJk, ISubstitutiveJk } from '../../../common/interfaces';
import { IJkForList } from '../../../http/get-jks-by-builder';
import { IRegion } from '../../../http/get-regions';
import { DropdownSearchField } from '../../dropdown-search';
import { FormPanel } from '../form-panel/index';
import { createJkLabelText } from '../helpers';
import { CompetitiveJkTab } from './tab';

import * as styles from './index.css';

export interface ICompetitiveJkPanelProps {
  regions: IRegion[];
  competitiveJks: IJkForList[];
  builderJks: IJkForList[];
  competitiveJkList: ICompetitiveJk[];
  onChange(value: ICompetitiveJk[]): void;
}

export interface ICompetitiveJkPanelState {
  selectedJkId?: number;
}

export class CompetitiveJkPanel extends React.Component<ICompetitiveJkPanelProps, ICompetitiveJkPanelState> {
  public state: ICompetitiveJkPanelState = {};

  public UNSAFE_componentWillReceiveProps(nextProps: ICompetitiveJkPanelProps) {
    const { selectedJkId } = this.state;

    if (!nextProps.competitiveJks.find(jk => jk.id === selectedJkId)) {
      this.setState({ selectedJkId: undefined });
    }
  }

  public render() {
    const { builderJks, competitiveJks, regions, competitiveJkList } = this.props;
    const { selectedJkId } = this.state;

    return (
      <FormPanel title="Выбор конкурентных ЖК (не более 10)">
        <div {...mergeStyles('row', styles['dropdown-container'])}>
          <DropdownSearchField
            label="ЖК других застройщиков в регионах размещения спецпроекта"
            value={selectedJkId || 0}
            values={this.getDropdownValues()}
            onChange={(value: number) => this.setState({ selectedJkId: value })}
            className="col-md-6 col-sm-8 form-group"
          />
          <div className="col-md-6 col-sm-4 form-group">
            <button
              className="btn btn-success"
              onClick={this.handleAddCompetitiveJk}
              disabled={!selectedJkId || competitiveJkList.length >= 10}
            >
              Добавить
            </button>
          </div>
        </div>
        <div className="panel-group">
          {competitiveJkList.map(({ id, substitutiveJkList, regionId }) => {
            const currentJk = competitiveJks.find(jk => jk.id === id);
            const filteredBuilderJks = builderJks.filter(jk => jk.newRegion === regionId);

            return (
              currentJk && (
                <CompetitiveJkTab
                  key={id}
                  builderJks={filteredBuilderJks}
                  regions={regions}
                  label={createJkLabelText(currentJk, regions)}
                  substitutiveJkList={substitutiveJkList || []}
                  onChange={value => this.handleChangeSubstitutiveJkList(id, value)}
                  onDelete={() => this.handleDeleteCompetitiveJk(id)}
                />
              )
            );
          })}
        </div>
      </FormPanel>
    );
  }

  private getDropdownValues = () => {
    const { competitiveJkList, competitiveJks, regions } = this.props;

    return competitiveJks
      .filter(competitiveJk => !competitiveJkList.some(jk => jk.id === competitiveJk.id))
      .map(jk => ({
        label: createJkLabelText(jk, regions),
        value: jk.id,
      }));
  };

  private handleDeleteCompetitiveJk = (competitiveJkId: number) => {
    const { onChange, competitiveJkList } = this.props;

    onChange(competitiveJkList.filter(jk => jk.id !== competitiveJkId));
  };

  private handleAddCompetitiveJk = () => {
    const { selectedJkId } = this.state;
    const { onChange, competitiveJks, competitiveJkList } = this.props;

    const selectedJk = selectedJkId && competitiveJks.find(jk => jk.id === selectedJkId);
    if (!selectedJk) {
      return;
    }

    onChange(
      competitiveJkList.concat({
        id: selectedJk.id,
        regionId: selectedJk.newRegion,
        substitutiveJkList: [],
      }),
    );

    this.setState({ selectedJkId: undefined });
  };

  private handleChangeSubstitutiveJkList = (competitiveJkId: number, substitutiveJkList: ISubstitutiveJk[]) => {
    const { onChange, competitiveJkList } = this.props;

    const newCompetitiveJkList = competitiveJkList.map(jk => {
      return jk.id === competitiveJkId ? { ...jk, substitutiveJkList } : jk;
    });

    onChange(newCompetitiveJkList);
  };
}
