import { IApplicationState } from '../../../shared/types/redux';
import {
  IApplicationContext,
  IApplicationSpecialPromosContext,
  IApplicationSpecialPromosCreateContext,
  IApplicationSpecialPromosEditContext,
} from '../../../shared/types/applicationContext';
import { createReduxStore } from '../../../shared/utils/redux';

export function createApplicationState(
  context:
    | IApplicationContext
    | IApplicationSpecialPromosContext
    | IApplicationSpecialPromosCreateContext
    | IApplicationSpecialPromosEditContext,
) {
  const { config } = context;

  const initialState = config.getStrict<IApplicationState>('initialState');

  return createReduxStore(initialState, true);
}
