import { ErrorLogComponent } from '@cian/error-log-component';
import { UiKitContextProvider } from '@cian/ui-kit/context';
import { uaToDeviceType } from '@cian/ui-kit/utils';
import * as React from 'react';
import { Provider } from 'react-redux';

import { TReduxStore } from '../../types/redux';
import { IApplicationContext } from '../../types/applicationContext';
import { ApplicationContext } from '../../utils/applicationContext';
import { Auth } from '../../components/auth';

export interface IApplicationProps {
  context: IApplicationContext;
  reduxStore: TReduxStore;
  authUrl: string;
}

export const AuthContainer: React.FC<IApplicationProps> = ({ reduxStore, context, authUrl }) => {
  const {
    logger,
    httpApi,
    custom: { userAgent },
  } = context;

  return (
    <ErrorLogComponent logger={logger}>
      <ApplicationContext.Provider value={context}>
        <UiKitContextProvider deviceType={uaToDeviceType(userAgent || undefined)}>
          <Provider store={reduxStore}>
            <Auth httpApi={httpApi} authUrl={authUrl} />
          </Provider>
        </UiKitContextProvider>
      </ApplicationContext.Provider>
    </ErrorLogComponent>
  );
};
