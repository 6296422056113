import { ErrorLogComponent } from '@cian/error-log-component';
import { UiKitContextProvider } from '@cian/ui-kit/context';
import { uaToDeviceType } from '@cian/ui-kit/utils';
import * as React from 'react';
import { Provider } from 'react-redux';
import { CreateSpecialPromo } from '../../components/create-special-promo';

import { TReduxStore } from '../../types/redux';
import { ApplicationContext } from '../../utils/applicationContext';
import { IApplicationSpecialPromosCreateContext } from '../../types/applicationContext';

export interface IApplicationProps {
  context: IApplicationSpecialPromosCreateContext;
  reduxStore: TReduxStore;
}

export const SpecialPromosCreateContainer: React.FC<IApplicationProps> = ({ reduxStore, context }) => {
  const {
    logger,
    httpApi,
    custom: { builders, regions, builderJks, competitiveJks, userAgent },
  } = context;

  return (
    <ErrorLogComponent logger={logger}>
      <ApplicationContext.Provider value={context}>
        <UiKitContextProvider deviceType={uaToDeviceType(userAgent || undefined)}>
          <Provider store={reduxStore}>
            <CreateSpecialPromo
              httpApi={httpApi}
              competitiveJks={competitiveJks}
              builders={builders}
              builderJks={builderJks}
              regions={regions}
            />
          </Provider>
        </UiKitContextProvider>
      </ApplicationContext.Provider>
    </ErrorLogComponent>
  );
};
