import * as React from 'react';

import { IAdminCreateSpecialPromoRequest } from '../../repositories/special-promos/v3/admin-create-special-promo';
import { IHttpApi } from '@cian/http-api/shared';

import { createSpecialPromo } from '../../http/create-special-promo';
import { IBuilder } from '../../http/get-builder-list';
import { IJkForList } from '../../http/get-jks-by-builder';
import { IRegion } from '../../http/get-regions';
import { Alert } from '../alert';
import { SpecialPromoForm } from '../special-promo-form';

interface IProps {
  httpApi: IHttpApi;
  builders: IBuilder[];
  regions: IRegion[];
  builderJks: IJkForList[];
  competitiveJks: IJkForList[];
}

interface IState {
  saving: boolean;
  showDangerModal: boolean;
}

export class CreateSpecialPromo extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      saving: false,
      showDangerModal: false,
    };
  }
  public render() {
    const { builders, regions, httpApi, builderJks, competitiveJks } = this.props;

    return (
      <div className="container-md">
        {this.state.showDangerModal && (
          <Alert
            onClick={() => this.setState({ showDangerModal: false })}
            text="Не удалось сохранить данные"
            type="danger"
          />
        )}

        <SpecialPromoForm
          builders={builders}
          saving={this.state.saving}
          title="Создание шаблона спецпроекта"
          regions={regions}
          httpApi={httpApi}
          builderJks={builderJks}
          competitiveJks={competitiveJks}
          onSubmit={this.handleSubmitForm}
        />
      </div>
    );
  }

  private handleSubmitForm = async (specialPromo: IAdminCreateSpecialPromoRequest) => {
    const { httpApi } = this.props;
    if (!httpApi) {
      return;
    }

    this.setState({ saving: true });

    try {
      const { id } = await createSpecialPromo(httpApi, specialPromo);
      window.location.href = `/special-promos/${id}/?success=true`;
    } catch (err) {
      this.setState({ saving: false, showDangerModal: true });
      window.setTimeout(() => this.setState({ showDangerModal: false }), 3000);
    }
  };
}
