import * as React from 'react';

import { mergeStyles } from '@cian/utils/lib/shared/style';

import * as styles from './index.css';

interface ITextField {
  value: string;
  onChange(value: string): void;
  containerClass?: string;
  inputClass?: string;
  htmlId?: string;
  label?: string;
  placeholder?: string;
  maxLength?: number;
  required?: boolean;
  invalid?: boolean;
}

export const TextField = (props: ITextField) => (
  <div {...mergeStyles(props.containerClass, styles['text-field'])}>
    {props.label && (
      <label className="form-label">
        {props.label}&nbsp;
        {props.required && <span className="text-danger">*</span>}
      </label>
    )}
    <input
      {...mergeStyles('form-control', props.inputClass, props.invalid && styles['invalid'])}
      id={props.htmlId || ''}
      value={props.value}
      placeholder={props.placeholder}
      maxLength={props.maxLength}
      onChange={e => props.onChange(e.currentTarget.value)}
    />
  </div>
);
