import * as React from 'react';

import { Outside } from '@cian/ui-kit';
import { mergeStyles } from '@cian/utils/lib/shared/style';

import { getFilteredDropdownValues } from '../../common/helpers';
import { IDropdownValue } from '../../components/select-field';

import * as styles from './index.css';

interface IMultiselectorFieldProps {
  onChange(value: (string | number)[]): void;
  value: (string | number)[];
  values: IDropdownValue[];
  label?: string;
  className?: string;
  noEmpty?: boolean;
  required?: boolean;
  disabled?: boolean;
}

interface IMultiselectorFieldState {
  dropdownIsOpen: boolean;
  search: string;
  className?: string;
}

export class MultiselectorField extends React.Component<IMultiselectorFieldProps, IMultiselectorFieldState> {
  public constructor(props: IMultiselectorFieldProps) {
    super(props);

    this.state = {
      dropdownIsOpen: false,
      search: '',
    };
  }

  public render() {
    const { dropdownIsOpen, search } = this.state;
    const { values, required, className, label, disabled } = this.props;

    const filteredValues = getFilteredDropdownValues(values, search);

    return (
      <div {...mergeStyles(className, styles['multiselector-field'])}>
        {label && (
          <label>
            {label}&nbsp;
            {required && <span className="text-danger">*</span>}
          </label>
        )}
        <div className="dropdown">
          <Outside onOutside={() => this.handleClickOutside()}>
            <div>
              <button
                disabled={disabled}
                {...mergeStyles('btn btn-default', styles['dropdown-btn'])}
                onClick={() => this.setState({ dropdownIsOpen: !dropdownIsOpen })}
              >
                {this.getButtonText()}
                <span {...mergeStyles('caret', styles['caret'])} />
              </button>
              {dropdownIsOpen && (
                <ul {...mergeStyles('dropdown-menu', styles['dropdown-menu'])}>
                  <li className={styles['search']}>
                    <input
                      type="text"
                      className="form-control"
                      onChange={e => this.setState({ search: e.currentTarget.value })}
                      placeholder="Поиск"
                    />
                  </li>
                  {filteredValues.map((value, index) => (
                    <li key={index} className={styles['list-item']}>
                      <label className={styles['label']}>
                        <input
                          type="checkbox"
                          checked={this.isSelectedValue(value.value)}
                          onChange={() => this.toggleValue(value.value)}
                        />
                        &nbsp;
                        {value.label}
                      </label>
                    </li>
                  ))}
                  {!filteredValues.length && (
                    <li className={styles['list-item']}>{search ? 'По вашему запросу ничего не найдено' : 'Пусто'}</li>
                  )}
                </ul>
              )}
            </div>
          </Outside>
        </div>
      </div>
    );
  }

  private getButtonText() {
    const selectedValues = this.props.values.filter(dropdownValue => this.isSelectedValue(dropdownValue.value));
    if (selectedValues.length) {
      return selectedValues.map(dropdownValue => dropdownValue.label).join(', ');
    }

    return 'Не выбрано';
  }

  private isSelectedValue(value: string | number) {
    return this.props.value.includes(value);
  }

  private toggleValue(value: string | number) {
    if (this.isSelectedValue(value)) {
      if (this.props.noEmpty && this.props.value.length === 1) {
        return;
      }

      this.props.onChange(this.props.value.filter(select => select !== value));
    } else {
      this.props.onChange([...this.props.value, value]);
    }
  }

  private handleClickOutside() {
    if (this.state.dropdownIsOpen) {
      this.setState({ dropdownIsOpen: false });
    }
  }
}
