import * as React from 'react';

import { IMapSettingsJk } from '../../../common/interfaces';
import { CoordinateField } from '../../coordinate-field';
import { NumberField } from '../../number-field';
import { FormPanel } from '../form-panel';

interface IProps {
  mapSettingsJk: IMapSettingsJk;
  onChange(value: IMapSettingsJk): void;
  useValidation: boolean;
}

const validateZoomValue = (zoom?: number) => {
  if (!zoom) {
    return 0;
  } else if (zoom > 255) {
    return 255;
  }

  return zoom;
};

export const MapSettingsPanel = (props: IProps) => {
  const { mapSettingsJk, onChange, useValidation } = props;

  return (
    <FormPanel title="Блок с картой (отображение ЖК на карте)">
      <div className="row">
        <NumberField
          label="Масштаб карты"
          className="col-md-6 form-group"
          value={mapSettingsJk.zoom}
          onChange={value => onChange({ ...mapSettingsJk, zoom: validateZoomValue(value) })}
          placeholder="от 1 до 255"
          minValue={1}
          maxValue={255}
          invalid={useValidation && !mapSettingsJk.zoom}
          required
          disableFractional
        />
        <CoordinateField
          required
          invalidLat={useValidation && !mapSettingsJk.lat}
          invalidLng={useValidation && !mapSettingsJk.lng}
          label="Координаты центра карты"
          className="col-md-6 form-group"
          onChange={value => onChange({ ...mapSettingsJk, lat: value.lat || 0, lng: value.lng || 0 })}
          value={{ lat: mapSettingsJk.lat, lng: mapSettingsJk.lng }}
        />
      </div>
    </FormPanel>
  );
};
