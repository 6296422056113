// tslint:disable:max-line-length

import * as React from 'react';

export const helperIcon = (
  // eslint-disable-next-line react/forbid-elements
  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 16 16">
    <path
      fill="#2b87db"
      fillOpacity="0.5"
      fillRule="evenodd"
      d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm-1-5v2h2v-2H7zm.134-1.57h1.644c-.01-.498.033-.844.125-1.037.093-.193.33-.45.713-.767.74-.614 1.22-1.1 1.446-1.456.226-.357.34-.735.34-1.135 0-.72-.31-1.354-.923-1.898-.615-.543-1.442-.815-2.482-.815-.988 0-1.786.27-2.394.806-.608.537-.936 1.19-.983 1.96l1.664.205c.116-.537.328-.936.638-1.2.31-.26.694-.392 1.153-.392.477 0 .856.127 1.138.378.28.252.422.554.422.906 0 .253-.08.485-.24.696-.102.133-.417.414-.946.844-.53.43-.88.816-1.058 1.16-.176.344-.264.782-.264 1.315l.006.43z"
    />
  </svg>
);
