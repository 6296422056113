import { EDemoActionTypes } from '../../actions/demo';
import { IConfigFeatures } from '../../types/features';
import { TReduxActions } from '../../types/redux';

const defaultFetures: IConfigFeatures = {
  test: false,
};

export function demoReducer(state: IConfigFeatures = defaultFetures, { type, payload }: TReduxActions) {
  if (type === EDemoActionTypes.SetFeatures) {
    return {
      ...state,
      ...payload,
    };
  }

  return state;
}
