import * as React from 'react';
import { hydrateRoot } from 'react-dom/client';

import { TReduxStore } from '../../../shared/types/redux';
import {
  IApplicationContext,
  IApplicationSpecialPromosContext,
  IApplicationSpecialPromosContextCustom,
  IApplicationSpecialPromosCreateContext,
  IApplicationSpecialPromosCreateContextCustom,
  IApplicationSpecialPromosEditContext,
  IApplicationSpecialPromosEditContextCustom,
  TContext,
} from '../../../shared/types/applicationContext';
import { SpecialPromosContainer } from '../../../shared/containers/SpecialPromos';
import { SpecialPromosCreateContainer } from '../../../shared/containers/Create';
import { SpecialPromosEditContainer } from '../../../shared/containers/Edit';
import { AuthContainer } from '../../../shared/containers/Auth';

import * as routes from '../../../shared/routes';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-grid.css';
import 'bootstrap/dist/css/bootstrap-reboot.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-datepicker/dist/react-datepicker.css';

interface IDependencies {
  store: TReduxStore;
  context: TContext;
}

export function renderApplication(dependencies: IDependencies) {
  const { context, store } = dependencies;
  const { config, custom } = context;

  const projectName = config.getStrict<string>('projectName');
  const rootElement = document.getElementById(projectName);

  const { route } = custom;
  const authUrl = config.get<string>('special-promos.authUrl');

  let component;

  switch (route) {
    case routes.SPECIAL_PROMOS_ROUTE:
      if (!(custom as IApplicationSpecialPromosContextCustom).spList) {
        throw new Error('spList not configured');
      }
      if (!(custom as IApplicationSpecialPromosContextCustom).filter) {
        throw new Error('filter not configured');
      }
      if (!(custom as IApplicationSpecialPromosContextCustom).regions) {
        throw new Error('regions not configured');
      }
      if (typeof (custom as IApplicationSpecialPromosContextCustom).totalPages !== 'number') {
        throw new Error('totalPages not configured');
      }

      component = <SpecialPromosContainer reduxStore={store} context={context as IApplicationSpecialPromosContext} />;
      break;
    case routes.CREATE_SPECIAL_PROMO_ROUTE:
      if (!(custom as IApplicationSpecialPromosCreateContextCustom).builders) {
        throw new Error('builders not configured');
      }
      if (!(custom as IApplicationSpecialPromosCreateContextCustom).regions) {
        throw new Error('regions not configured');
      }
      if (!(custom as IApplicationSpecialPromosCreateContextCustom).builderJks) {
        throw new Error('builderJks not configured');
      }

      component = (
        <SpecialPromosCreateContainer reduxStore={store} context={context as IApplicationSpecialPromosCreateContext} />
      );
      break;
    case routes.EDIT_SPECIAL_PROMO_ROUTE:
      if (!(custom as IApplicationSpecialPromosEditContextCustom).builders) {
        throw new Error('builders not configured');
      }
      if (!(custom as IApplicationSpecialPromosEditContextCustom).regions) {
        throw new Error('regions not configured');
      }
      if (!(custom as IApplicationSpecialPromosEditContextCustom).builderJks) {
        throw new Error('builderJks not configured');
      }
      if (!(custom as IApplicationSpecialPromosEditContextCustom).specialPromo) {
        throw new Error('specialPromo not configured');
      }

      component = (
        <SpecialPromosEditContainer reduxStore={store} context={context as IApplicationSpecialPromosEditContext} />
      );
      break;
    case routes.AUTH_ROUTE:
      if (!authUrl) {
        throw new Error('authUrl is not configured');
      }

      component = <AuthContainer authUrl={authUrl} reduxStore={store} context={context as IApplicationContext} />;
      break;
    default:
      component = <div />;
  }

  if (!rootElement) {
    throw new Error(`Unable to find element #${projectName}`);
  }

  hydrateRoot(rootElement, component);
}
