import * as React from 'react';

import { IAdminSpecialPromoListSchema } from '../../types/adminGetSpecialPromoList';
import { IHttpApi } from '@cian/http-api/shared';

import { getQueryStringByFilter } from '../../common/helpers';
import { IFilter, pageSizeDropdownValues } from '../../common/interfaces';
import { deleteSpecialPromo } from '../../http/delete-special-promo';
import { IRegion } from '../../http/get-regions';
import { getSpecialPromoList, ISpecialPromoForList } from '../../http/get-special-promo-list';
import { CREATE_SPECIAL_PROMO_ROUTE, SPECIAL_PROMOS_ROUTE } from '../../routes';
import { Alert } from '../alert';
import { SelectField } from '../select-field';
import { Pagination } from './pagination';
import { SpecialPromoFilter } from './special-promo-filter';
import { SpecialPromoTable } from './special-promo-table';

import * as styles from './index.css';

interface IProps {
  httpApi: IHttpApi;
  spList: ISpecialPromoForList[];
  totalPages: number;
  filter: IFilter;
  regions: IRegion[];
}

interface IState {
  filter: IFilter;
  spList: ISpecialPromoForList[];
  showDangerModal: boolean;
  totalPages: number;
}

export class SpecialPromos extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      filter: props.filter,
      showDangerModal: false,
      spList: props.spList,
      totalPages: props.totalPages,
    };
  }

  public render() {
    const { regions } = this.props;
    const { filter, spList, showDangerModal, totalPages } = this.state;

    return (
      <div className={`container-md ${styles['container']}`}>
        {showDangerModal && (
          <Alert
            onClick={() => this.setState({ showDangerModal: false })}
            text="Не удалось удалить проект, попробуйте повторить попытку позже"
            type="danger"
          />
        )}
        <h2 className={styles['title']}>Шаблоны спецпроектов</h2>
        <a className="btn btn-success" href={CREATE_SPECIAL_PROMO_ROUTE}>
          Создать
        </a>
        <SpecialPromoFilter filter={filter} onChange={value => this.setState({ filter: value })} regions={regions} />
        <SpecialPromoTable onDelete={this.handleDeleteSpecialPromo} specialPromos={spList} regions={regions} />
        <div className="row">
          <SelectField
            canEmpty
            emptyText="Все"
            label="На странице"
            className="col"
            values={pageSizeDropdownValues}
            value={filter.pageSize}
            onChange={(value?: string) => {
              this.fetchSpecialPromoList({ ...filter, pageSize: Number(value) || '', p: 1 });
            }}
          />
          <div className="col">
            <Pagination
              filter={filter}
              pageCount={totalPages}
              onPageChange={p => this.fetchSpecialPromoList({ ...filter, p })}
            />
          </div>
        </div>
      </div>
    );
  }

  private fetchSpecialPromoList(filter: IFilter) {
    const { httpApi } = this.props;
    if (!httpApi) {
      return;
    }

    getSpecialPromoList(httpApi, filter)
      .then(({ spList, totalPages }: IAdminSpecialPromoListSchema) => {
        window.history.replaceState({}, '', `${SPECIAL_PROMOS_ROUTE}?${getQueryStringByFilter(filter)}`);

        this.setState({ filter, spList, totalPages });
      })
      .catch(err => {
        throw err;
      });
  }

  private handleDeleteSpecialPromo = (specialPromoId: number) => {
    const { httpApi } = this.props;
    if (!httpApi) {
      return;
    }

    deleteSpecialPromo(httpApi, specialPromoId)
      .then(() => this.fetchSpecialPromoList(this.state.filter))
      .catch(() => {
        this.setState({ showDangerModal: true });
        window.setTimeout(() => this.setState({ showDangerModal: false }), 3000);
      });
  };
}
